import Link from "next/link";
import { useRouter } from "next/router";

import Button from "./Button";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

function NotFound() {
  const router = useRouter();
  const { topicId, chapterId } = router.query;

  if (topicId || chapterId) {
    router.push("/nachschlagen");
  }

  return (
    <main className="grid h-[calc(100vh-4rem)] place-items-center bg-white px-6 py-24 dark:bg-gray-900 sm:py-32 lg:px-8">
      <div className="text-center">
        <p className="text-base font-semibold text-orange-600">404</p>
        <h1 className="mt-4 text-3xl font-bold tracking-tight text-slate-900 dark:text-slate-200 sm:text-5xl">
          Seite nicht gefunden
        </h1>
        <p className="mt-6 text-base leading-7 text-gray-600 dark:text-slate-400">
          Leider konnten wir die Seite nicht finden.
        </p>
        <div className="mt-10 flex flex-wrap items-center justify-center gap-4 text-sm">
          <Link href="/" passHref className="focus:outline-none">
            <Button type="primaryGhost" size="medium" Icon={ArrowLeftIcon}>
              Zurück zur Startseite
            </Button>
          </Link>
          <a
            href="mailto:kontakt@jurahilfe.de?subject=Seite nicht gefunden"
            className="btn-default focus-ring rounded p-1 text-sm font-semibold text-gray-900 dark:text-slate-200"
          >
            Support kontaktieren
          </a>
        </div>
      </div>
    </main>
  );
}

export default NotFound;
