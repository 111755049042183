import { useState, useEffect, useCallback } from "react";
import { useRouter } from "next/router";

/**
 * Custom hook to handle page transition loading states
 *
 * @returns {Object} Object containing loading state and methods to control it
 * @property {boolean} isLoading - Whether a page transition is in progress
 * @property {Function} startLoading - Function to manually trigger the loading state
 * @property {Function} stopLoading - Function to manually stop the loading state
 */
export function usePageTransition() {
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);

  // Set up router event listeners for loading state
  useEffect(() => {
    const handleStart = () => setIsLoading(true);
    const handleComplete = () => setIsLoading(false);

    router.events.on("routeChangeStart", handleStart);
    router.events.on("routeChangeComplete", handleComplete);
    router.events.on("routeChangeError", handleComplete);

    return () => {
      router.events.off("routeChangeStart", handleStart);
      router.events.off("routeChangeComplete", handleComplete);
      router.events.off("routeChangeError", handleComplete);
    };
  }, [router]);

  // Function to manually start loading
  const startLoading = useCallback(() => {
    setIsLoading(true);
  }, []);

  // Function to manually stop loading
  const stopLoading = useCallback(() => {
    setIsLoading(false);
  }, []);

  return {
    isLoading,
    startLoading,
    stopLoading,
  };
}
