import {
  collection,
  getDocs,
  query,
  where,
  limit,
  orderBy,
} from "firebase/firestore";
import { firestore } from "./firebase-init";
import { TopicTitle } from "@jurahilfe/shared/types";

import {
  BaseDeckFirestore,
  ChapterFirestore,
  ChapterOutline,
  DeckOutline,
  Topic,
  TopicOutline,
  UserDeck,
} from "@types";

// Utility function to get user data
const getUserData = async (path: string, userId?: string) => {
  if (!userId) return null;
  const userSnap = await getDocs(collection(firestore, path));
  return userSnap.docs.reduce((acc, doc) => {
    acc[doc.id] = doc.data();
    return acc;
  }, {} as Record<string, any>);
};

/**
 * Merges user progress data with the base data.
 * @param baseData The base data to be merged.
 * @param userData The user data to be merged.
 */
const mergeUserProgress = <T>(
  baseData: T,
  userData: Record<string, any> | null
): T => {
  // Default user progress
  const defaultData: UserDeck = {
    progress: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
    },
    stepTimes: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
    },
  };
  const mergedData = { ...baseData } as any;
  for (const key in userData) {
    mergedData[key] = userData?.[key] || defaultData;
  }
  return mergedData;
};

/**
 * Get the outline for a topic with chapters and decks, including the number of cards
 * and questions in each deck. If a userId is provided, the user's progress for the topic
 * will be included in the response.
 * @param title The title of the topic to get the outline for
 * @param userId Optional user ID to get the user's progress for the topic
 */
export const getTopicOutline = async (
  title: TopicTitle,
  userId?: string
): Promise<TopicOutline> => {
  // Get the topic
  const topicsRef = collection(firestore, "topics");
  const baseTopicSnap = await getDocs(
    query(topicsRef, where("title", "==", title), limit(1))
  );

  if (baseTopicSnap.empty) {
    // throw new Error("No topic found for title: " + title);
    console.warn("No topic found for title: " + title);
    return null;
  }

  const topicSnapshot = baseTopicSnap.docs[0];
  const topicId = topicSnapshot.id;

  // Get the user progress for the topic
  const userTopicProgress = userId
    ? await getUserData(`userProgress/${userId}/forTopics`, userId)
    : null;

  const topic: TopicOutline = {
    id: topicId,
    chapters: [],
    userProgress: mergeUserProgress(
      userTopicProgress?.[topicId],
      userTopicProgress
    ),
    ...(topicSnapshot.data() as Topic),
  };

  // Get the chapters and user data concurrently
  const [baseChapterSnaps, userChapterData] = await Promise.all([
    getDocs(
      query(
        collection(firestore, "topics", topicId, "chapters"),
        orderBy("sortOrder")
      )
    ),
    getUserData(
      `userProgress/${userId}/forTopics/${topicId}/forChapters`,
      userId
    ),
  ]);

  topic.chapters = baseChapterSnaps.docs.map((baseChapterSnap) => {
    const userProgress = mergeUserProgress(
      userChapterData?.[baseChapterSnap.id],
      userChapterData
    );

    const chapter: ChapterOutline = {
      id: baseChapterSnap.id,
      ...(baseChapterSnap.data() as ChapterFirestore),
      decks: [],
      topicTitle: topic.title,
      topicKey: topic.topicKey,
      topicId,
      userProgress,
    };
    return chapter;
  });

  // Fetch decks and user deck data concurrently for each chapter
  const deckPromises = topic.chapters.map(async (chapter) => {
    const [baseDeckSnaps, userDeckData] = await Promise.all([
      getDocs(
        query(
          collection(
            firestore,
            "topics",
            topicId,
            "chapters",
            chapter.id,
            "decks"
          ),
          orderBy("sortOrder")
        )
      ),
      getUserData(
        `userProgress/${userId}/forTopics/${topicId}/forChapters/${chapter.id}/forDecks`,
        userId
      ),
    ]);

    chapter.decks = baseDeckSnaps.docs.map((baseDeckSnap) => {
      const userProgress = mergeUserProgress(
        userDeckData?.[baseDeckSnap.id],
        userDeckData
      );

      const deck: DeckOutline = {
        id: baseDeckSnap.id,
        ...(baseDeckSnap.data() as BaseDeckFirestore),
        topicTitle: topic.title,
        topicKey: topic.topicKey,
        topicId,
        chapterId: chapter.id,
        userProgress,
      };
      return deck;
    });
  });

  await Promise.all(deckPromises);

  return topic;
};
