import React, { useState, useEffect, useCallback } from "react";
import { useRouter } from "next/router";
import useStore from "@hooks/useStore";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import toast from "react-hot-toast";
import Link from "next/link";
import { collection, getDocs, query, where } from "firebase/firestore";
import { getAuth } from "firebase/auth";

import { Button, Spinner } from "@jurahilfe/shared/components";

import PaymentInstructionDisplay from "@components/app/Upgrade/PaymentInstructionDisplay";
import PaymentSuccessDisplay from "@components/app/Upgrade/PaymentSuccessDisplay";

import { firestore } from "@utils/firebase/firebase-init";
import { startCustomerPortal } from "@utils/startStripeCustomerPortal";
import { startStripeSubscribePage } from "@utils/startStripeSubscribePage";

// Add bullet points for each productid here
const productBulletPoints: Record<string, string[]> = {
  // TODO: Add production product ids here
  prod_P3loKbowUk29U0: [
    "Alle Inhalte im Zivilrecht (17 Skripte mit 1.000+ Wissenselementen)",
    "Wiederholungsfragen zu jedem Thema",
    "450+ Multiple Choice Fragen zur Wissenskontrolle",
    "Suchfunktion für alle Inhalte",
    "Leitfäden zum Jurastudium",
    "Zugriff auf alle neuen Inhalte",
  ],
};

const PlanSelector = () => {
  const router = useRouter();
  const user = useStore((state) => state.user);

  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(true);

  const [loading, setLoading] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(false);

  useEffect(() => {
    getProducts();

    // Check to see if this is a redirect back from Checkout
    if (router.query?.success) {
      setPaymentSuccess(true);

      // Fore a refresh of the custom claims to display the new role
      getAuth()
        .currentUser.getIdTokenResult(true)
        .then((idTokenResult) => {
          if (idTokenResult.claims.stripeRole === "pro") {
            toast.success("Dein Abo wurde erfolgreich angepasst!");
          } else {
            toast.error(
              "Es ist ein Fehler aufgetreten. Bitte logge dich aus und ein und versuche es nochmal.",
              {
                duration: 4000,
              }
            );
          }
        });
    }

    if (router.query?.canceled) {
      setPaymentSuccess(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCustomerPortal = useCallback(
    () => startCustomerPortal(user, setLoading, router),
    [user, router]
  );

  const handleSubscribe = useCallback(
    (publicPrice = "") =>
      startStripeSubscribePage(user, publicPrice, router, setLoading, toast),
    [user, router]
  );

  const getProducts = async () => {
    const prods = [];
    setProductsLoading(true);
    const baseProdQuerySnap = await getDocs(
      query(collection(firestore, "products"), where("active", "==", true))
    );
    for (const docSnap of baseProdQuerySnap.docs) {
      const prod = { ...docSnap.data(), id: docSnap.id };
      const priceSnap = await getDocs(collection(docSnap.ref, "prices"));
      for (const doc of priceSnap.docs) {
        prod["prices"] = [
          ...(prod["prices"] || []),
          { id: doc.id, ...doc.data() },
        ];
      }
      prods.push(prod);
    }
    setProducts(prods);
    setProductsLoading(false);
  };
  return (
    <div className="flex w-full flex-col">
      {productsLoading ? (
        <div className="flex h-96 w-full items-center justify-center">
          <Spinner className={"h-10 w-10"} />
        </div>
      ) : (
        products.map((product) => {
          return (
            <div key={product.id} className="">
              <div className="relative mx-auto max-w-7xl">
                <div className="mx-auto max-w-lg overflow-hidden rounded-lg border border-slate-100 dark:border-slate-800 lg:flex lg:max-w-none">
                  <div className="flex-1 bg-white px-6 py-6 dark:bg-gray-900 lg:px-12 lg:py-8">
                    <h3 className="text-2xl font-extrabold text-slate-900 dark:text-slate-100 sm:text-3xl">
                      {product.name}
                    </h3>
                    <p className="mt-6 text-base leading-6 text-slate-500 dark:text-slate-300">
                      {product.description}
                    </p>
                    <div className="mt-8">
                      <div className="flex items-center">
                        <h4 className="flex-shrink-0 bg-white pr-4 text-sm font-semibold uppercase tracking-wider text-slate-600 dark:bg-gray-900 dark:text-slate-300">
                          Deine Vorteile im Überblick
                        </h4>
                        <div className="flex-1 border-t-2 border-slate-200 dark:border-slate-300" />
                      </div>
                      <ul
                        role="list"
                        className="mt-8 space-y-5 lg:grid lg:grid-cols-2 lg:gap-x-8 lg:gap-y-5 lg:space-y-0"
                      >
                        {(
                          productBulletPoints?.[product.id] || [
                            "Platzhalter 1",
                            "Platzhalter 2",
                            "Platzhalter 3",
                            "Platzhalter 4",
                          ]
                        ).map((feature) => (
                          <li
                            key={feature}
                            className="flex items-start lg:col-span-1"
                          >
                            <div className="flex-shrink-0">
                              <CheckCircleIcon
                                className="h-5 w-5 text-lime-500"
                                aria-hidden="true"
                              />
                            </div>
                            <p className="ml-3 text-sm text-slate-600 dark:text-slate-300">
                              {feature}
                            </p>
                          </li>
                        ))}
                      </ul>
                      <div className="mt-5 flex w-full justify-center text-slate-500">
                        <Link href="/" target="_blank">
                          <Button
                            type="tertiary"
                            size="small"
                            className="font-normal"
                          >
                            Mehr Infos
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="bg-slate-50 px-6 py-8 text-center dark:bg-gray-800 lg:flex lg:flex-shrink-0 lg:flex-col lg:justify-center lg:p-8">
                    {paymentSuccess || user?.claims?.stripeRole === "pro" ? (
                      <PaymentSuccessDisplay
                        loading={loading}
                        handleCustomerPortal={handleCustomerPortal}
                      />
                    ) : (
                      <PaymentInstructionDisplay
                        product={product}
                        handleSubscribe={handleSubscribe}
                        loading={loading}
                        trialEligible={user?.trialEligible}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })
      )}
      {/* Placeholder for Video Element */}
      {/* <div className="pb-16 sm:pb-20 ">
                    <div className="relative mx-auto max-w-7xl sm:px-6 lg:px-8">
                      <div className="h-96 w-full bg-red-200" />
                    </div>
                  </div> */}
    </div>
  );
};

export default PlanSelector;
