import useStore from "@hooks/useStore";
import { FeedbackItemType } from "@utils/firebase/saveContentFeedback";
import clsx from "clsx";
import { FC } from "react";

export interface FeedbackButtonProps {
  itemType: FeedbackItemType;
  itemId: string;
  question: string;
  cardTitle?: string;
  onDark?: boolean;
}

/**
 * Button that opens the content feedback modal.
 * @param param0
 * @returns
 */
const FeedbackButton: FC<FeedbackButtonProps> = ({
  itemType,
  itemId,
  question,
  cardTitle,
}) => {
  const { set } = useStore((state) => ({
    set: state.set,
  }));

  const referenceData = {
    itemType: itemType,
    itemId: itemId,
    question: question,
    // Conditionally add cardTitle if it exists
    ...(cardTitle && { cardTitle: cardTitle }),
  };

  return itemType && itemId && question ? (
    <button
      className={clsx(
        "focus-ring rounded-full px-2  text-xs font-medium text-slate-500 transition dark:text-slate-400 dark:focus:ring-offset-slate-800 sm:text-sm"
      )}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        set(() => ({
          showContentFeedbackModal: {
            open: true,
            reference: referenceData,
          },
        }));
      }}
    >
      Feedback
    </button>
  ) : null;
};

export default FeedbackButton;
