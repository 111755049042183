import { useEffect, useState } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";

import CookieTable from "./CookieTable";
import { useSharedStore } from "../../hooks/useSharedStore";
import {
  CookieDetails as CookieDetailsType,
  CookieCategory,
} from "../../types";

const CookieDetails = ({
  setShowDetails,
  isEmbedded = false,
  showStatistic,
  showMarketing,
  showExternalMedia,
}) => {
  const cookieDetails = useSharedStore((state) => state.cookieDetails);
  const [cookieDetail, setCookieDetail] =
    useState<CookieDetailsType | undefined>(undefined);
  const [currentCategory, setCurrentCategory] =
    useState<CookieCategory>("Notwendig");

  useEffect(() => {
    if (!cookieDetails) return;
    const currentCategoryIndex = cookieDetails.findIndex(
      (detail) => detail.category === currentCategory
    );
    if (currentCategoryIndex !== -1)
      setCookieDetail(cookieDetails[currentCategoryIndex]);
  }, [currentCategory, cookieDetails]);

  return (
    <div
      className={clsx(
        isEmbedded
          ? "mt-4"
          : "fixed bottom-0 left-0 z-40 w-full max-w-5xl px-2 pb-4 sm:px-4 sm:pb-5"
      )}
    >
      <div className="rounded-lg border bg-white dark:bg-gray-800 dark:border-gray-700 p-3 shadow-lg sm:p-4">
        <div className="grid sm:grid-cols-4">
          {/* Details navigation */}
          <div className="mt-9 space-y-1 sm:col-span-1 sm:mt-0">
            {cookieDetails?.map(
              (cookieDetail) =>
                (cookieDetail.category === "Notwendig" ||
                  (cookieDetail.category === "Statistik" && showStatistic) ||
                  (cookieDetail.category === "Marketing" && showMarketing) ||
                  (cookieDetail.category === "Externe Medien" &&
                    showExternalMedia)) && (
                  <button
                    key={cookieDetail.category}
                    onClick={() => {
                      setCurrentCategory(cookieDetail.category);
                    }}
                    className={clsx(
                      currentCategory === cookieDetail.category
                        ? "bg-gray-100 dark:bg-gray-700 text-gray-900 dark:text-white"
                        : "text-gray-600 dark:text-gray-400 hover:bg-gray-50 hover:text-gray-900 dark:hover:bg-gray-700 dark:hover:text-white",
                      "flex w-full items-center rounded-md px-3 py-2 text-sm font-medium focus-ring"
                    )}
                  >
                    {cookieDetail.category}
                  </button>
                )
            )}
          </div>
          {/* Details information */}
          {cookieDetail && (
            <div className="relative max-h-[500px] w-full overflow-y-auto px-1 sm:col-span-3 sm:px-3 focus:outline-none">
              <div className="mt-2 sm:mt-0">
                <h3 className="text-base font-medium text-gray-900 dark:text-white">
                  {cookieDetail.category}
                </h3>
                <p className="mt-1 text-sm text-gray-500 dark:text-gray-400">
                  {cookieDetail.descriptionText}
                </p>
              </div>
              <CookieTable cookies={cookieDetail.cookies} />
            </div>
          )}
        </div>
      </div>
      {!isEmbedded && (
        <div className="absolute right-5 top-2">
          <button
            type="button"
            onClick={() => {
              setShowDetails(false);
            }}
            className=" flex rounded-md p-2 hover:bg-gray-100  dark:hover:bg-gray-700 dark:hover:text-white focus-ring"
          >
            <span className="sr-only">Dismiss</span>
            <XMarkIcon
              className="h-6 w-6 text-gray-700 dark:text-gray-400"
              aria-hidden="true"
            />
          </button>
        </div>
      )}
    </div>
  );
};

export default CookieDetails;
