import React from "react";
import { BaseCard } from "../../../types";
import ActionCard from "../../shared/ActionCard";

interface ReaderCardProps {
  card: BaseCard;
  highlightTag?: string;
}

const ReaderCard: React.FC<ReaderCardProps> = ({ card, highlightTag }) => {
  return (
    <div key={card.id} className="mx-auto flex justify-center">
      <ActionCard
        card={card}
        config={{
          defaultContent: "answer",
          actions: ["toggleContent", "playAudio", "feedback"],
        }}
        title={card.question}
        className="my-1 w-full"
        highlightTag={highlightTag}
        withContentFeedbackCollection={{
          itemType: "card",
          itemId: card.id,
          cardTitle: card.title,
          question: card.question,
        }}
      />
    </div>
  );
};

export default ReaderCard;
