import { useEffect, useState, useCallback } from "react";

import { StripeProduct } from "../types";
import { PricingTier, tiers } from "../config/pricingTiers";
import { extendTierData } from "../utils/extendTierData";

export const useExtendedTiers = (
  stripeProducts: StripeProduct[],
  activeProductId?: string,
  activeInterval?: "annually" | "monthly"
): [
  extendedTiers: PricingTier[],
  extendTiers: (products: StripeProduct[]) => PricingTier[]
] => {
  const [extendedTiers, setExtendedTiers] = useState<PricingTier[]>(tiers);
  const extendTiers = useCallback(
    (products: StripeProduct[]) => {
      return tiers.map((tier) => {
        const stripeId =
          process.env.NODE_ENV === "development"
            ? tier.stripeIdDev
            : tier.stripeId;

        const stripeProduct = products.find(
          (product) => product.id === stripeId
        );
        const extendedTier = extendTierData(tier, stripeProduct);
        return {
          ...extendedTier,
          active:
            stripeId === activeProductId || (!activeProductId && tier.isFree), // TODO: Handle Free = Active
          activeInterval: activeInterval,
        };
      });
    },
    [activeInterval, activeProductId]
  );

  useEffect(() => {
    if (stripeProducts.length > 0) {
      setExtendedTiers(extendTiers(stripeProducts));
    }
  }, [stripeProducts, extendTiers, activeProductId, activeInterval]);

  return [extendedTiers, extendTiers];
};
