import { Modal } from "@jurahilfe/shared/components";

import useStore from "@hooks/useStore";

const ConnectedModal = () => {
  const { modal, closeModal } = useStore((state) => ({
    modal: state.modal,
    closeModal: state.closeModal,
  }));

  return (
    <Modal
      title={modal.title}
      open={modal.isOpen}
      type={modal.type}
      confirmButtonText={modal.confirmButtonText}
      onConfirm={modal.onConfirm}
      setClose={closeModal}
      cancelButtonText={modal.cancelButtonText}
    >
      {modal.content}
    </Modal>
  );
};

export default ConnectedModal;
