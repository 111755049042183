import { useCallback, useState } from "react";
import { RadioGroup, Radio, Label } from "@headlessui/react";
import clsx from "clsx";

import { StripeProduct, PricingTier as PricingTierType } from "../../types";

import Button from "../Button";
import Tier from "./PricingTier";
import Badge from "../Badge";
import BackgroundPattern2 from "../BackgroundPattern2";

import { useExtendedTiers } from "../../hooks/useExtendedTiers";

export type PricingPageType = "public" | "conversion" | "upgrade";

export interface PriceFrequency {
  value: "monthly" | "annually";
  label: string;
  priceSuffix: string;
  priceDiscount?: string;
  stripeName?: "year" | "month";
}

const frequencies: PriceFrequency[] = [
  {
    value: "monthly",
    label: "Monatlich",
    priceSuffix: "/Monat",
    stripeName: "month",
  },
  {
    value: "annually",
    label: "Jährlich",
    priceSuffix: "/Jahr",
    priceDiscount: "🥳 2 Monate geschenkt", // "Spare 20%",
    stripeName: "year",
  },
];

export default function PricingHighlights({
  header,
  type = "public",
  products: stripeProducts,
  onTierClick,
  activeProductId, // Used to define ids of the active tier
  activeInterval,
  className,
  trialEligible,
  layout = "stacked",
  loading = false,
}: {
  header: React.ReactNode;
  type: PricingPageType;
  products?: StripeProduct[];
  onTierClick?: (
    priceId: string,
    tier: PricingTierType,
    frequency: PriceFrequency
  ) => void;
  activeProductId?: string;
  className: string;
  activeInterval?: "annually" | "monthly";
  trialEligible?: boolean;
  layout?: "stacked" | "horizontal";
  loading?: boolean;
}) {
  const [frequency, setFrequency] = useState<PriceFrequency>(frequencies[1]);
  const [extendedTiers] = useExtendedTiers(
    stripeProducts,
    activeProductId,
    activeInterval
  );

  // Calculate the comparison price
  const calculateComparisonPrice = useCallback(() => {
    const mostPopularTier = extendedTiers.find((tier) => tier.mostPopular);
    if (!mostPopularTier) return 0;

    const otherTiers = extendedTiers.filter((tier) => !tier.mostPopular);
    const totalPrice = otherTiers.reduce((sum, tier) => {
      const price =
        tier.price?.[frequency.value].displayInfo?.pricePerIntervalNumber || 0;
      return sum + (price || 0);
    }, 0);

    return totalPrice;
  }, [extendedTiers, frequency]);

  return (
    <div
      className={clsx(
        "relative isolate overflow-hidden bg-white dark:bg-gray-900",
        className
      )}
    >
      <BackgroundPattern2 />
      <div className="max-w-8xl mx-auto px-6 lg:px-8">
        {header}
        <div
          className={clsx(
            "mx-auto mt-16 flex max-w-2xl lg:max-w-none",
            "justify-center"
          )}
        >
          <RadioGroup
            value={frequency}
            onChange={setFrequency}
            className="grid grid-cols-2 gap-x-2 rounded-lg bg-gray-400/10 p-1 text-center text-xs font-semibold leading-5 text-white dark:bg-white/5"
          >
            <Label className="sr-only">Zahlungsinterval</Label>
            {frequencies.map((option) => (
              <Radio
                key={option.value}
                value={option}
                className={({ checked }) =>
                  clsx(
                    "focus-ring",
                    checked
                      ? "bg-gradient-to-br from-orange-500 to-pink-500"
                      : "text-gray-500 dark:text-white",
                    "relative flex cursor-pointer select-none flex-col items-center justify-center gap-y-1 rounded px-2.5 py-1 sm:flex-row"
                  )
                }
              >
                <span>{option.label}</span>
                {option.priceDiscount && (
                  // Workaround to make badge stand out more
                  <div
                    className={clsx(
                      frequency.value === option.value ? "" : "grayscale",
                      "rounded-lg",
                      "absolute -top-2.5  -translate-y-full md:-top-3",
                      "left-1/2  -translate-x-1/2"
                    )}
                  >
                    <Badge type="upgrade" className="relative text-xs">
                      {option.priceDiscount}
                      <div className="absolute -bottom-1 left-1/2 h-0 w-0 -translate-x-1/2 border-x-4 border-t-4 border-x-transparent border-t-pink-600/75" />
                    </Badge>
                  </div>
                )}
              </Radio>
            ))}
          </RadioGroup>
        </div>

        <div className="isolate mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-8 lg:mx-0 lg:max-w-none lg:grid-cols-3 xl:grid-cols-10">
          {/* Add lg:translate-x-10 lg:translate-y-10 and remove my-10 when Pro Plus is in center */}
          {layout === "stacked" && (
            <div className="-z-10 lg:my-10 xl:col-span-3 xl:mt-20">
              <Tier
                type={type}
                key={extendedTiers[0].id}
                tier={extendedTiers[0]}
                frequency={frequency}
                loading={loading}
                onClick={onTierClick}
                trialEligible={trialEligible}
                gridLayout={layout}
                tierLayout="full"
              />
            </div>
          )}
          <div
            className={clsx(
              layout === "horizontal"
                ? "col-span-3 xl:col-span-10"
                : "xl:col-span-4"
            )}
          >
            <Tier
              type={type}
              key={extendedTiers[1].id}
              tier={extendedTiers[1]}
              frequency={frequency}
              loading={loading}
              onClick={onTierClick}
              comparisonPrice={calculateComparisonPrice()}
              trialEligible={trialEligible}
              gridLayout={layout}
              tierLayout="full"
            />
          </div>

          <div
            className={clsx(
              layout === "horizontal"
                ? "-z-10 col-span-3 grid -translate-y-9 items-start gap-6 px-5 lg:grid-cols-3 xl:col-span-10"
                : "flex flex-col gap-8 lg:my-10 xl:col-span-3 xl:my-20"
            )}
          >
            {/* Only show max 3 tiers in stacked view */}
            {extendedTiers.slice(2, 5).map((tier) => (
              <Tier
                type={type}
                key={tier.id}
                tier={tier}
                tierLayout="stacked"
                frequency={frequency}
                loading={loading}
                onClick={onTierClick}
                trialEligible={trialEligible}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="mx-auto mb-1 max-w-7xl px-6 lg:px-8">
        <div className="mt-16 flex flex-col items-center justify-center">
          {/* Give the user the option to book a call or text us if they have questions or special offers */}
          <h2 className="text- max-w-md text-center text-gray-700 dark:text-gray-200">
            <span className="font-semibold">Noch nicht zufrieden? </span>
            <br />
            Hol dir ein individuelles Angebot.
          </h2>
          <div className="mt-4 flex gap-2">
            <a
              href="https://calendly.com/jurahilfe/jurahilfe-beta"
              target="_blank"
              rel="noreferrer"
            >
              <Button type="primaryGhost" size="small">
                Buche einen Video-Call
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
