import React from "react";
import { motion } from "framer-motion";
import ContentDisplay from "./ContentDisplay";

export interface ContentItem {
  content: string;
  id: string | number;
  hideLinkTags?: string[];
}

interface AnimatedContentDisplayProps {
  contentItems: ContentItem[];
  activeItemId: string | number;
  className?: string;
  highlightTag?: string;
}

/**
 * Generic Animated Content Display component
 * Handles smooth crossfade transitions between multiple content views
 */
const AnimatedContentDisplay: React.FC<AnimatedContentDisplayProps> = ({
  contentItems,
  activeItemId,
  className,
  highlightTag,
}) => {
  if (!contentItems.length) {
    return null;
  }

  // Find active content item
  const activeItem =
    contentItems.find((item) => item.id === activeItemId) || contentItems[0];

  // Animation variants for content transitions
  const contentVariants = {
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.4,
        ease: "easeInOut",
      },
    },
    hidden: {
      opacity: 0,
      scale: 0.98,
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
  };

  return (
    <div className={`relative overflow-hidden ${className || ""}`}>
      {/* Render each content item */}
      {contentItems.map((item) => (
        <motion.div
          key={item.id}
          className="absolute inset-0 w-full"
          initial={item.id === activeItemId ? "visible" : "hidden"}
          animate={item.id === activeItemId ? "visible" : "hidden"}
          variants={contentVariants}
          style={{ zIndex: item.id === activeItemId ? 2 : 1 }}
        >
          <ContentDisplay
            htmlString={item.content}
            hideLinkTags={item.hideLinkTags}
            highlightTag={highlightTag}
            className="transition-all duration-300 ease-in-out"
          />
        </motion.div>
      ))}

      {/* Invisible spacer div to maintain proper height */}
      <div className="invisible">
        <ContentDisplay
          htmlString={activeItem.content}
          hideLinkTags={activeItem.hideLinkTags}
          highlightTag={highlightTag}
        />
      </div>
    </div>
  );
};

export default AnimatedContentDisplay;
