declare global {
  interface Window {
    heap: any | undefined;
  }
}

const isSSR = typeof window === "undefined";

export const track = (event: string, data?: Record<string, any>) => {
  if (!isSSR && window && window.heap) {
    window.heap?.track(event, data);
  }
};

export const identify = (userId: string) => {
  if (!isSSR && window && window.heap) {
    window.heap?.identify(userId);
  }
};

export const resetIdentity = () => {
  if (!isSSR && window && window.heap) {
    window.heap?.resetIdentity();
  }
};

export const addUserProperties = (
  properties: Record<string, string | number>
) => {
  if (!isSSR && window && window.heap) {
    window.heap?.addUserProperties(properties);
  }
};
