import { doc, setDoc } from "firebase/firestore";
import toast from "react-hot-toast";

import { track } from "@jurahilfe/shared/utils";

import { OnboardingStepId } from "@types";

import useStore from "@hooks/useStore";

import { firestore } from "@utils/firebase/firebase-init";
import { getOnboardingSteps } from "@utils/getOnboardingSteps";

export const completeOnboardingStep = async (stepId: OnboardingStepId) => {
  const store = useStore.getState();
  const currentProgress = store.user?.settings?.onboarding?.steps || {};
  const userId = store.user?.uid;

  if (!userId) {
    console.error("User ID not found for onboarding step completion");
    return;
  }

  // Check locally if the step is already completed to avoid unnecessary Firestore updates
  if (currentProgress[stepId]) {
    return;
  }

  track("Onboarding Step Completed", { stepId });
  // Update Firestore with the new progress
  const docRef = doc(
    firestore,
    "customers",
    userId,
    "appSettings",
    "onboarding"
  );

  await setDoc(
    docRef,
    {
      steps: {
        [stepId]: true,
      },
    },
    { merge: true }
  );

  const onboardingSteps = getOnboardingSteps();
  const step = onboardingSteps.find((step) => step.id === stepId);

  toast.success(`"${step.title}" abgeschlossen!`, {
    duration: 5000,
  });
};
