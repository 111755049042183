import Link from "next/link";
import clsx from "clsx";
import { LockClosedIcon } from "@heroicons/react/20/solid";

import { Annotation, Logo, Badge } from "@jurahilfe/shared/components";

import { ContentLimitWithProPlus } from "@types";

const topicKeyMap: Record<ContentLimitWithProPlus, string> = {
  zr: "Pro Zivilrecht",
  strr: "Pro Strafrecht",
  oeffr: "Pro Öffentliches Recht",
  jaw: "Pro Juristisches Allgemeinwissen",
  pro: "Pro Bonus",
  guides: "Pro Ratgeber",
  proPlus: "Pro+ Bonus", // Bonus",
};

const topicKeyMapShortened: Record<ContentLimitWithProPlus, string> = {
  zr: "Pro ZR",
  strr: "Pro StR",
  oeffr: "Pro ÖR",
  jaw: "Pro JAW",
  pro: "Bonus",
  guides: "Pro Ratgeber", // Not used
  proPlus: "Pro+ Bonus",
};

const tooltipMap: Record<ContentLimitWithProPlus, string> = {
  // Temporarily remove Pro Plus mention
  // zr: "Der Zugriff auf alle Inhalte im Zivilrecht ist in der Zivilrecht Pro und Pro Plus Mitgliedschaft enthalten.",
  zr: "Der Zugriff auf alle Inhalte im Zivilrecht ist in der Zivilrecht Pro enthalten.",
  strr: "Der Zugriff auf alle Inhalte im Strafrecht ist in der Strafrecht Pro und Pro Plus Mitgliedschaft enthalten.",
  oeffr:
    "Der Zugriff auf alle Inhalte im öffentlichen Recht ist in der Öffentliches Recht Pro und Pro Plus Mitgliedschaft enthalten.",
  jaw: "Der Zugriff auf alle Inhalte im juristischen Allgemeinwissen ist in der Juristisches Allgemeinwissen Pro und Pro Plus Mitgliedschaft enthalten.",
  pro: "Diese Inhalte sind also Bonusinhalte in jeder Pro Mitgliedschaft enthalten.",
  guides:
    "Diese Inhalte sind als Bonusinhalte in der Pro Ratgeber Mitgliedschaft enthalten.", // Not used
  proPlus:
    "Diese Inhalte sind als Bonusinhalte in der Pro Plus Mitgliedschaft enthalten.",
};

export const getTooltipContent = (
  subscriptionType: ContentLimitWithProPlus
) => {
  // Temp handle "jaw" as "proPreview" -> then will be proPlus
  if (subscriptionType === "jaw") {
    return tooltipMap["proPlus"];
  }

  return tooltipMap[subscriptionType];
};

export const getBadgeContent = (
  subscriptionType: ContentLimitWithProPlus,
  shortened?: boolean
) => {
  // Temp handle "jaw" as "proPreview" -> then will be proPlus
  if (subscriptionType === "jaw") {
    return shortened ? topicKeyMapShortened["proPlus"] : topicKeyMap["proPlus"];
  }
  return shortened
    ? topicKeyMapShortened[subscriptionType]
    : topicKeyMap[subscriptionType];
};

const ProBadge = ({
  size = "medium",
  className,
  subscriptionType,
  type = "default",
  hideTooltip,
}: {
  size?: "small" | "medium" | "large";
  className?: string;
  subscriptionType?: ContentLimitWithProPlus;
  type?: "default" | "shortened" | "minimal";
  hideTooltip?: boolean;
}) => {
  const LinkBadge = (
    <Link
      href="/upgrade"
      className={clsx(
        "focus-ring flex max-h-fit items-center rounded-md",
        hideTooltip || !subscriptionType ? className : ""
      )}
    >
      {type === "minimal" ? (
        <LockClosedIcon className="h-3 w-3 text-slate-400 dark:text-slate-500" />
      ) : (
        <Badge size={size} type="upgrade">
          <LockClosedIcon
            className={clsx(
              "mr-1",
              size === "small"
                ? "-mt-0.5 h-2 w-2"
                : size === "medium"
                ? "h-3 w-3"
                : "h-4 w-4"
            )}
          />

          {subscriptionType
            ? getBadgeContent(subscriptionType, type === "shortened")
            : "Pro"}
        </Badge>
      )}
    </Link>
  );

  if (!hideTooltip && subscriptionType) {
    return (
      <Annotation
        content={
          <div className={clsx(className, "flex items-center gap-2 lg:gap-3")}>
            <Logo size="xsmall" />
            <div className="text-left">
              {getTooltipContent(subscriptionType)}
            </div>
          </div>
        }
        placement="top"
      >
        {LinkBadge}
      </Annotation>
    );
  }

  return LinkBadge;
};

export default ProBadge;
