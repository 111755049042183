import React from "react";
import {
  Menu,
  MenuButton,
  MenuItems,
  MenuItem,
  MenuSeparator,
} from "@headlessui/react";
import { UserIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import Link from "next/link";

import { UserMenuItem } from "../../types";

const UserMenu = ({
  userMenuConfig,
}: {
  userMenuConfig: {
    photoURL?: string;
    email?: string;
    items: UserMenuItem[];
    extra?: React.ReactNode;
  };
}) => {
  return (
    <Menu as="div" className="relative">
      <MenuButton
        className={clsx(
          "btn-text-default rounded-full text-sm",
          userMenuConfig.photoURL ? "flex items-center" : "p-1"
        )}
      >
        <span className="sr-only">Nutzermenu öffnen</span>
        {userMenuConfig.photoURL ? (
          <img
            className="h-8 w-8 rounded-full"
            src={userMenuConfig.photoURL}
            alt="User profile"
          />
        ) : (
          <UserIcon className="h-6 w-6" aria-hidden="true" />
        )}
      </MenuButton>

      <MenuItems
        transition
        className={clsx(
          "absolute right-0 z-20 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none dark:bg-gray-800 dark:ring-gray-700",
          "origin-top transition duration-25 ease-out data-[closed]:scale-95 data-[closed]:opacity-0",
          "w-56"
        )}
      >
        {userMenuConfig.extra && userMenuConfig.extra !== null && (
          <>
            <div className="mb-3 mt-1.5">{userMenuConfig.extra}</div>
            <CustomMenuSeparator />
          </>
        )}
        {userMenuConfig.items.map((item, i) => {
          if (item.name === "divider") {
            return <CustomMenuSeparator key={`${item.name}-${i}`} />;
          }
          return (
            <MenuItem key={item.name} as="div">
              {({ focus, close }) => {
                const commonClassNames = clsx(
                  focus
                    ? "bg-gray-100 dark:bg-gray-700/70 dark:text-white"
                    : "",
                  item.highlighted
                    ? "font-semibold !text-primary-default"
                    : "text-gray-700 dark:text-slate-200",
                  " px-4 py-2 text-sm",
                  "mx-1 rounded",
                  "flex items-center"
                );

                if (item.onClick) {
                  return (
                    <button
                      onClick={() => {
                        item.onClick?.();
                        close();
                      }}
                      className={clsx(commonClassNames, "w-full text-left")}
                    >
                      {item.icon && <item.icon className="w-4 h-4 mr-2" />}
                      {item.name}
                    </button>
                  );
                }

                return item.href ? (
                  <Link
                    href={item.href}
                    {...(item.newTab && {
                      target: "_blank",
                      rel: "noopener noreferrer",
                    })}
                    className={commonClassNames}
                    onClick={() => close()}
                  >
                    {item.icon && <item.icon className="w-4 h-4 mr-2" />}
                    {item.name}
                  </Link>
                ) : null;
              }}
            </MenuItem>
          );
        })}
      </MenuItems>
    </Menu>
  );
};

export default UserMenu;

const CustomMenuSeparator = () => {
  return (
    <MenuSeparator
      as="div"
      className="border-slate-200 border-t mx-auto my-1 dark:border-slate-700"
    />
  );
};
