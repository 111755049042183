import React, { useEffect, useRef } from "react";
import { useCookies } from "react-cookie";
import PlausibleProvider from "next-plausible";

import { COOKIE_NAMES } from "../../config/cookies";
import { useSharedStore } from "../../hooks/useSharedStore";

import CookieBanner from "../CookieBanner";
import { initHeap, removeHeap } from "./providers/heap";
import { initMetaPixel, removeMetaPixel } from "./providers/meta";

export interface AnalyticsConfig {
  plausibleDomain: string;
  heapId?: string;
  metaPixelId?: string;
  privacyPolicyLink: string;
}

/**
 * AnalyticsProvider is a wrapper component that initializes the analytics providers
 * and provides the CookieBanner component for GDPR compliance.
 */
const AnalyticsProvider = ({
  children,
  config,
}: {
  children: React.ReactNode;
  config: AnalyticsConfig;
}) => {
  const [cookies] = useCookies([
    COOKIE_NAMES.STATISTIC,
    COOKIE_NAMES.MARKETING,
  ]);
  const { cookieChoiceUpdated, set } = useSharedStore((state) => ({
    cookieChoiceUpdated: state.cookieChoiceUpdated,
    set: state.set,
  }));

  const prevConsent = useRef({
    statistics: cookies[COOKIE_NAMES.STATISTIC] === "true",
    marketing: cookies[COOKIE_NAMES.MARKETING] === "true",
  });

  useEffect(() => {
    if (window.location.hostname === "localhost") return;

    const currentConsent = {
      statistics: cookies[COOKIE_NAMES.STATISTIC] === "true",
      marketing: cookies[COOKIE_NAMES.MARKETING] === "true",
    };

    // Handle Meta Pixel
    if (config.metaPixelId) {
      if (currentConsent.marketing && !prevConsent.current.marketing) {
        initMetaPixel(config.metaPixelId, () => {
          set((state) => {
            state.analyticScriptIsLoaded = true;
          });
        });
      } else if (!currentConsent.marketing && prevConsent.current.marketing) {
        removeMetaPixel();
      }
    }

    // Handle Heap
    if (config.heapId) {
      if (currentConsent.statistics && !prevConsent.current.statistics) {
        initHeap(config.heapId, () => {
          set((state) => {
            state.analyticScriptIsLoaded = true;
          });
        });
      } else if (!currentConsent.statistics && prevConsent.current.statistics) {
        removeHeap();
      }
    }

    prevConsent.current = currentConsent;
  }, [cookieChoiceUpdated]);

  // Plausible is always active
  return (
    <PlausibleProvider
      domain={config.plausibleDomain}
      enabled={process.env.NODE_ENV === "production"}
      trackLocalhost={false}
    >
      {children}
      <CookieBanner
        privacyPolicyLink={config.privacyPolicyLink}
        showStatistic={true}
        showMarketing={true}
        showExternalMedia={false}
      />
    </PlausibleProvider>
  );
};

export default AnalyticsProvider;
