import { usePlausible } from "next-plausible";
import { track } from "../utils/heapEvents";

export const useTrack = () => {
  const plausible = usePlausible();

  const trackEvent = (eventName: string, props?: Record<string, string>) => {
    plausible(eventName, { props });
    track(eventName, props);
  };

  return trackEvent;
};
