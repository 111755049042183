import { useEffect } from "react";
import { useRouter } from "next/router";

import { shouldHideChatWidget } from "@jurahilfe/shared/utils";

import useStore from "@hooks/useStore";

export const useConditionalHideChatWidget = () => {
  const router = useRouter();

  const { user } = useStore((state) => ({
    user: state.user,
  }));

  // TODO: Refactor this to use the new shouldHideChatWidget hook
  const hideChatWidget = shouldHideChatWidget(
    router.pathname,
    user?.settings?.onboarding?.onboardingDismissed
  );

  // Add this useEffect to apply the class to the body
  useEffect(() => {
    if (hideChatWidget) {
      document.body.classList.add("hide-chat-widget");
    } else {
      document.body.classList.remove("hide-chat-widget");
    }
  }, [hideChatWidget, user?.settings?.onboarding?.onboardingDismissed]);

  return hideChatWidget;
};
