import { ExtendedFirebaseUser } from "@types";

import { extendTierData } from "@jurahilfe/shared/utils";
import { tiers } from "@jurahilfe/shared/config";
import { PricingTier,  } from "@jurahilfe/shared/types";

import { fetchStripeProduct } from "./firebase/fetchStripeProduct";

/**
 * Extends a pricing tier with the stripe product and the user's subscription status
 * @param pricingTierID The ID of the pricing tier to extend
 * @param user The user to extend the tier for
 * @returns The extended tier
 */
export const extendTier = async (
  pricingTierID: string,
  user: ExtendedFirebaseUser
): Promise<PricingTier | null> => {
  const tier = tiers.find((tier) => tier.id === pricingTierID);

  if (!tier) {
    console.error(`Could not find tier with id ${pricingTierID} to extend`);
    return null;
  }

  const stripeId =
    process.env.NODE_ENV === "development" ? tier.stripeIdDev : tier.stripeId;

  // Fetch the stripe product with the id, and get the prices from the subcollection
  const stripeProduct = await fetchStripeProduct(stripeId);

  if (!stripeProduct) {
    console.error(
      `Could not find stripe product with id ${stripeId} to extend tier ${pricingTierID}`
    );
    return null;
  }

  // TODO: Handle adding user active status (since extendTierData is used in PricingPage and UpgradePage)
  return extendTierData(tier, stripeProduct);
};
