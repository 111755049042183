import React, { ReactNode, useState, useEffect } from "react";
import clsx from "clsx";
import Card from "./Card";
import ContentDisplay from "./ContentDisplay";
import AnimatedContentDisplay, { ContentItem } from "./AnimatedContentDisplay";
import { Card as CardType, BaseCard } from "@types";
import {
  ClockIcon,
  Bars3BottomLeftIcon,
  ListBulletIcon,
  PlayIcon,
} from "@heroicons/react/24/outline";
import { FeedbackButtonProps } from "./FeedbackButton";
import TabSelector, { TabItem } from "./TabSelector";

export type ActionCardActionType = "toggleContent" | "playAudio" | "feedback";

export interface ActionCardConfig {
  defaultContent: "answer" | "answer_long"; // Which content field to use as primary content
  actions?: ActionCardActionType[]; // Which actions to display (if relevant content exists)
  useAnimatedTransition?: boolean; // Whether to use the animated transition between content views
}

export interface ActionCardProps {
  card: BaseCard | CardType | Record<string, any>; // The card data to display
  config: ActionCardConfig; // Configuration for display and actions
  title?: ReactNode; // Card title (overrides card.title if provided)
  showTitle?: boolean; // Whether to show the title
  className?: string; // Additional CSS classes
  isLast?: boolean; // Is this the last card in a list
  isHighlighted?: boolean; // Should the card be highlighted
  hasOutline?: boolean; // Should the card have an outline
  withContentFeedbackCollection?: FeedbackButtonProps; // Whether to show the feedback collection
  highlightTag?: string; // Tag to highlight in the content
}

const ActionCard: React.FC<ActionCardProps> = ({
  card,
  config,
  title,
  showTitle = true,
  className,
  isLast,
  isHighlighted,
  hasOutline,
  withContentFeedbackCollection,
  highlightTag,
}) => {
  const hasLongAnswer = Boolean(card.answer_long);
  const hasAudio = Boolean(card.answer_audio);
  const showPlayButton = config.actions?.includes("playAudio") && hasAudio;
  const showFeedbackButton = config.actions?.includes("feedback");
  // Default to true for useAnimatedTransition if not specified
  const useAnimation = config.useAnimatedTransition ?? true;

  // Set initial content type based on config AND availability of long answer
  const [showLongAnswer, setShowLongAnswer] = useState(
    config.defaultContent === "answer_long" && hasLongAnswer
  );

  // Reset to compact view if long answer becomes unavailable
  useEffect(() => {
    if (!hasLongAnswer && showLongAnswer) {
      setShowLongAnswer(false);
    }
  }, [hasLongAnswer, showLongAnswer]);

  // Determine the content to display
  const getCompactContent = () => {
    return card.answer_linked || card.answer || "";
  };

  const getLongContent = () => {
    return card.answer_long_linked || card.answer_long || "";
  };

  // Create content items for the animated display
  const contentItems: ContentItem[] = [
    {
      id: "compact",
      content: getCompactContent(),
      hideLinkTags: card.tags,
    },
  ];

  // Add detailed content if available
  if (hasLongAnswer) {
    contentItems.push({
      id: "detailed",
      content: getLongContent(),
      hideLinkTags: card.tags,
    });
  }

  const selectedTabIndex = showLongAnswer ? 1 : 0;
  const activeContentId = showLongAnswer ? "detailed" : "compact";

  // Define tab items for this specific card
  const tabItems: TabItem[] = [
    {
      icon: <ListBulletIcon />,
      text: "Kompakt",
    },
    {
      icon: hasLongAnswer ? <Bars3BottomLeftIcon /> : <ClockIcon />,
      text: "Ausführlich",
      disabled: !hasLongAnswer,
    },
  ];

  // Placeholder function for playing audio
  const handlePlayAudio = () => {
    console.log("Play audio placeholder - to be implemented");
    // TODO: Future implementation would go here
  };

  return (
    <Card
      className={clsx("relative", className)}
      isLast={isLast}
      isHighlighted={isHighlighted}
      hasOutline={hasOutline}
      withContentFeedbackCollection={
        showFeedbackButton ? withContentFeedbackCollection : null
      }
    >
      {showTitle && (title || card.title) && (
        <>
          <Card.Header className="flex-1">{title || card.title}</Card.Header>
          <div className="relative -mt-1 flex h-10 w-full items-center sm:-mt-2">
            <div className="h-[1px] w-full bg-slate-200 dark:bg-slate-700" />
            <div className="absolute inset-0 flex items-center justify-center sm:justify-end">
              <TabSelector
                items={tabItems}
                layoutId={`${card.id}-tab-selector`}
                selectedIndex={selectedTabIndex}
                onChange={(index) => setShowLongAnswer(index === 1)}
              />
              {showPlayButton && (
                <button
                  onClick={handlePlayAudio}
                  className="flex h-8 w-8 items-center justify-center rounded-full bg-orange-500 text-white transition hover:bg-orange-600 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 dark:bg-orange-600 dark:hover:bg-orange-500"
                  aria-label="Play audio"
                >
                  <PlayIcon className="h-4 w-4" />
                </button>
              )}
            </div>
          </div>
        </>
      )}
      {useAnimation ? (
        <AnimatedContentDisplay
          contentItems={contentItems}
          activeItemId={activeContentId}
          highlightTag={highlightTag}
        />
      ) : (
        <ContentDisplay
          htmlString={
            showLongAnswer && hasLongAnswer
              ? getLongContent()
              : getCompactContent()
          }
          hideLinkTags={card.tags}
          highlightTag={highlightTag}
        />
      )}
    </Card>
  );
};

export default ActionCard;
