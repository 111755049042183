import clsx from "clsx";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

import useStore from "@hooks/useStore";

const SearchButton = ({ classNames }: { classNames?: string }) => {
  const { set } = useStore((state) => ({
    searchOpen: state.searchOpen,
    set: state.set,
  }));

  const showSearch = () => {
    set((state) => {
      state.searchOpen = true;
    });
  };
  return (
    <button
      type="button"
      className={clsx(
        "focus-ring",
        "group flex items-center rounded-md bg-slate-100 p-2 px-4 dark:bg-gray-700 xl:w-64",
        classNames
      )}
      onClick={showSearch}
    >
      <span className="sr-only">Suche öffnen</span>
      <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
      <span className="ml-2 truncate text-sm text-slate-700 transition-colors group-hover:text-slate-600 dark:text-slate-300 dark:group-hover:text-slate-100">
        Inhalte durchsuchen
      </span>
    </button>
  );
};

export default SearchButton;
