import React, { useState, useEffect } from "react";
import { useTheme } from "next-themes";
import clsx from "clsx";
import { MoonIcon, SunIcon } from "@heroicons/react/24/solid";
import { DisclosureButton } from "@headlessui/react";

const ThemeSwitcher = ({
  type = "desktop",
}: {
  type?: "desktop" | "mobile";
}) => {
  const [mounted, setMounted] = useState(false);
  const { theme, setTheme } = useTheme();

  // useEffect only runs on the client, so now we can safely show the UI
  useEffect(() => {
    setMounted(true);
  }, []);

  if (type === "mobile") {
    return (
      <div className="flex w-full items-center justify-end">
        <DisclosureButton
          onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
          className={clsx(
            "btn-text-default border border-slate-300 dark:border-slate-600",
            "flex items-center justify-end rounded-md px-2 py-1 text-sm"
          )}
        >
          <span className="sr-only">Theme wechseln</span>
          <span className="mr-1">Theme auf </span>
          {theme === "dark" ? (
            <SunIcon className="h-4 w-4" aria-hidden="true" />
          ) : (
            <MoonIcon className="h-4 w-4" aria-hidden="true" />
          )}
          <span className="ml-1">ändern</span>
        </DisclosureButton>
      </div>
    );
  } else {
    return (
      <button
        type="button"
        className={clsx("btn-text-default", "mr-3 rounded-full p-2")}
        onClick={() => setTheme(theme === "dark" ? "light" : "dark")}
      >
        <span className="sr-only">Theme wechseln</span>
        {theme === "dark" ? (
          <MoonIcon className="h-5 w-5" aria-hidden="true" />
        ) : (
          <SunIcon className="h-5 w-5" aria-hidden="true" />
        )}
      </button>
    );
  }
};

export default ThemeSwitcher;
