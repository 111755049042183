import { ContentLimit, StripeProduct } from "../types";
import { PriceDisplayInfo } from "../utils/getPriceDisplayInfo";

export interface PricingTier {
  name: React.ReactNode;
  subtitle?: string;
  id: string;
  stripeId: string;
  stripeIdDev?: string;
  // If set, this tier is free and no stripe price will be fetched
  isFree?: boolean;
  // User has subscribed to this tier
  active?: boolean;
  activeInterval?: "monthly" | "annually";
  mostPopular?: boolean;
  new?: boolean;
  comingSoon?: boolean;
  mainTier?: boolean;
  description: string;
  contentLimit?: ContentLimit;
  price?: {
    monthly: {
      note?: string;
      // Should be set to the current stripe price id (depending on env)
      stripePriceId: string;
      stripePriceIdDev?: string;
      displayInfo?: PriceDisplayInfo;
      trialPeriodDays?: number;
    };
    annually: {
      note?: string;
      // Should be set to the current stripe price id (depending on env)
      stripePriceId: string;
      stripePriceIdDev?: string;
      displayInfo?: PriceDisplayInfo;
      trialPeriodDays?: number;
    };
  };
  stripeProduct?: StripeProduct;
  features: (string | JSX.Element)[];
}

export const tiers: PricingTier[] = [
  {
    name: "Kostenlos BGB AT lernen",
    subtitle: "Free",
    description:
      "Lerne, wiederhole und teste dein Wissen zum wichtigen Allgemeinen Teil des BGB",
    id: "tier-free",
    stripeId: null,
    stripeIdDev: null,
    isFree: true,
    mostPopular: false,
    price: {
      monthly: {
        stripePriceId: null,
        stripePriceIdDev: null,
      },
      annually: {
        stripePriceId: null,
        stripePriceIdDev: null,
      },
    },
    features: [
      "6 Lernpfade mit interaktiven Skripten, Karteikarten, Falltraining und Abschlusstest",
      "150+ Lernelemente und Karteikarten",
      "50+ Multipe Choice-Aufgaben",
      "Intelligente Suchfunktion",
      "Interaktive Verlinkungen",
      // eslint-disable-next-line react/jsx-key
      <span>
        <strong>Rechtsgebiete:</strong> Willenserklärung - Rechtsgeschäft und
        Vertragsschluss - Geschäftsfähigkeit - Stellvertretung - Besonderheiten
        im Schuldverhältnis - Anfechtung - Weitere Einwendungen
      </span>,
      <span key="extension">
        <strong>JuraSense</strong> Chrome Extension
      </span>,
    ],
  },
  {
    name: (
      <span>
        Zivilrecht + Strafrecht + Bonusinhalte <br />
        <span className="text-sm font-medium">
          (+ Öffentliches Recht sobald verfügbar)
        </span>
      </span>
    ),
    subtitle: "Pro Plus",
    description:
      "Alle Inhalte von Jurahilfe.de zum günstigen Gesamtpreis + exklusive Bonusinhalte",
    id: "tier-pro-plus",
    stripeId: "prod_P8eeElAbv0iQZS",
    stripeIdDev: "prod_P3loKbowUk29U0",
    mostPopular: true,
    mainTier: true,
    price: {
      monthly: {
        note: "Sicher dir den Einführungspreis dauerhaft - auch wenn Öffentliches Recht verfügbar ist.",
        stripePriceId: "price_1PeX56AUV1YFKOgCUcWthEVj",
        stripePriceIdDev: "price_1PdtwGAUV1YFKOgCajB5KgUb",
        trialPeriodDays: 7,
      },
      annually: {
        note: "Spare 2 Monate gegenüber dem Monats-Abo. Sicher dir jetzt den Einführungspreis dauerhaft - auch wenn Öffentliches Recht verfügbar ist.",
        stripePriceId: "price_1PeX6PAUV1YFKOgCiemZRiCD",
        stripePriceIdDev: "price_1PeHhwAUV1YFKOgCmKlTDWqP",
        trialPeriodDays: 14,
      },
    },
    features: [
      "75+ Lernpfade mit interaktiven Skripten, Karteikarten, Falltraining und Abschlusstest",
      "1600+ Lernelemente und Karteikarten",
      "450+ Multipe Choice-Aufgaben",
      "Interaktive Verlinkungen",
      "Intelligente Suchfunktion",
      "Fortschrittstracking",
      "Fortlaufende Aktualisierungen",
      <span key="content">
        <strong>Zivilrecht:</strong> Zivilrechtliches Allgemeinwissen -
        Allgemeiner Teil des BGB - Schuldrecht Allgemeiner Teil - Vertragliche
        Schuldverhältnisse - Gesetzliche Schuldverhältnisse - Allgemeines
        Sachenrecht - Mobiliarsachenrecht - Immobiliarsachenrecht
      </span>,
      <span key="content">
        <strong>Strafrecht:</strong> Allgemeines - Allgemeiner Teil des StGB -
        Nichtvermögensdelikte - Vermögensdelikte - Strafprozessrecht
      </span>,
      <span key="content">
        <strong>Öffentliches Recht:</strong> bald verfügbar
      </span>,
      <span key="extension">
        <strong>JuraSense</strong> Chrome Extension
      </span>,
      <span key="extras">
        <strong className="text-orange-500">
          Ratgeber “How-to-Jura” (Bonus):
        </strong>{" "}
        Aufbau juristischer Arbeiten - Gutachtenstil-Trainer - Zitierratgeber
      </span>,
      <span key="extras">
        <strong className="text-orange-500">
          Juristisches Allgemeinwissen (Bonus):
        </strong>{" "}
        9 Lernpfade zur Arbeit im Gutachten, zum Rechts- und Gerichtswesen und
        Rechtsgeschichte; mit &quot;Latein für Angeber&quot;
      </span>,
    ],
  },
  {
    name: "Zivilrecht Hauptkurs",
    subtitle: "Pro ZR (Einzelrechtsgebiet)",
    id: "tier-zivilrecht",
    contentLimit: "zr",
    stripeId: "prod_PDnHLc8sY2wLGU",
    stripeIdDev: "prod_PD4YJYdSQkEAXY",
    mostPopular: false,
    comingSoon: false,
    description:
      "Deine Grundlage für Klausur, Hausarbeit und Examen (bald auch mit Nebengebieten)",
    price: {
      monthly: {
        stripePriceId: "price_1OPLhCAUV1YFKOgCd4BpzQ2t",
        stripePriceIdDev: "price_1OOePKAUV1YFKOgCPDvoLfaP",
        note: "Sicher dir den Einführungspreis dauerhaft.",
        trialPeriodDays: 7,
      },
      annually: {
        stripePriceId: "price_1OPLlIAUV1YFKOgCRqfD2KeE",
        stripePriceIdDev: "price_1OOeUHAUV1YFKOgCu17wwiRj",
        note: "Spare 2 Monate gegenüber dem Monats-Abo und sichere dir den Einführungspreis dauerhaft.",
        trialPeriodDays: 14,
      },
    },
    features: [
      "35+ Lernpfade mit interaktiven Skripten, Karteikarten, Falltraining und Abschlusstest",
      "1000+ Lernelemente und Karteikarten",
      "450+ Multipe Choice-Aufgaben",
      "Intelligente Suchfunktion",
      "Interaktive Verlinkungen",
      "Fortschrittstracking",
      "Fortlaufende Aktualisierungen",
      <span key="content">
        <strong>Teilgebiete:</strong> Zivilrechtliches Allgemeinwissen -
        Allgemeiner Teil des BGB - Schuldrecht Allgemeiner Teil - Vertragliche
        Schuldverhältnisse - Gesetzliche Schuldverhältnisse - Allgemeines
        Sachenrecht - Mobiliarsachenrecht - Immobiliarsachenrecht
      </span>,
      <span key="extension">
        <strong>JuraSense</strong> Chrome Extension
      </span>,
    ],
  },
  {
    name: "Strafrecht",
    subtitle: "Pro StrR (Einzelrechtsgebiet)",
    id: "tier-straftrecht",
    comingSoon: false,
    stripeId: "prod_PEbHgNCRootIGD",
    stripeIdDev: "prod_PDrlM6bN6X6AXb",
    mostPopular: false,
    new: true,
    price: {
      monthly: {
        stripePriceId: "price_1OQ84zAUV1YFKOgCdjQGU9rN",
        stripePriceIdDev: "price_1OPQ2LAUV1YFKOgCGSJt8KaP",
        note: "Sicher dir den Einführungspreis dauerhaft.",
        trialPeriodDays: 7,
      },
      annually: {
        stripePriceId: "price_1PeX3TAUV1YFKOgC3UidjXWj",
        stripePriceIdDev: "price_1PeHkfAUV1YFKOgCWXR4Qr5f",
        note: "Spare 2 Monate gegenüber dem Monats-Abo und sichere dir den Einführungspreis dauerhaft.",
        trialPeriodDays: 14,
      },
    },
    description: "Komplettes Wissen für Deine Prüfungen im Strafrecht",
    features: [
      "25 Lernpfade mit interaktiven Skripten, Karteikarten, Falltraining und Abschlusstest",
      "550+ Lernelemente und Karteikarten",
      "340+ Multipe Choice-Aufgaben (bisher)",
      "Intelligente Suchfunktion",
      "Interaktive Verlinkungen",
      "Fortschrittstracking",
      "Fortlaufende Aktualisierungen",
      <span key="content">
        <strong>Teilgebiete:</strong> Allgemeines - Allgemeiner Teil des StGB -
        Nichtvermögensdelikte - Vermögensdelikte - Strafprozessrecht
      </span>,
      <span key="extension">
        <strong>JuraSense</strong> Chrome Extension
      </span>,
    ],
  },
  {
    name: "Öffentliches Recht",
    subtitle: "Pro ÖffR (Einzelrechtsgebiet)",
    id: "tier-oeffentliches-recht",
    comingSoon: true,
    stripeId: "prod_PEbHaQoSbYRotL",
    stripeIdDev: "prod_PDrne5tnUkE0dQ",
    description:
      "Alles was du Wissen musst im Staatsrecht und Verwaltungsrecht",
    features: [
      "700+ Lernelemente und Karteikarten",
      "350+ Multipe Choice-Aufgaben",
      "Intelligente Suchfunktion",
      "Interaktive Verlinkungen",
      <span key="content">
        <strong>Inhalte:</strong> Staatsorganisationsrecht - Grundrechte -
        Verfassungsprozessrecht - Allgemeines Verwaltungsprozessrecht -
        Allgemeines Verwaltungsverfahrensrecht - Besonderes Verwaltungsrecht -
        Europarecht
      </span>,
    ],
  },
];
