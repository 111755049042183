import { Cookie } from "../../types";

const CookieTable = ({ cookies }: { cookies: Cookie[] }) => {
  const tableHeaders = ["Cookie", "Dauer", "Herkunft", "Beschreibung"];

  return (
    <div className="mt-4 flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg dark:border-gray-700">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead className="bg-gray-50 dark:bg-gray-800">
                <tr>
                  {tableHeaders.map((headerName) => (
                    <th
                      key={headerName}
                      scope="col"
                      className="px-4 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500 dark:text-gray-400"
                    >
                      {headerName}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {cookies.map((cookie, idx) => (
                  <tr
                    key={cookie.cookieName}
                    className={
                      idx % 2 === 0
                        ? "bg-white dark:bg-gray-600"
                        : "bg-gray-50 dark:bg-gray-700"
                    }
                  >
                    <td className="whitespace-pre-line px-4 py-4 text-sm font-medium text-gray-900 dark:text-white">
                      {cookie.cookieName}
                    </td>
                    <td className="whitespace-pre-line px-4 py-4 text-sm text-gray-500 dark:text-gray-400">
                      {cookie.cookieDuration}
                    </td>
                    <td className="whitespace-pre-line px-4 py-4 text-sm text-gray-500 dark:text-gray-400">
                      {cookie.cookieService}
                    </td>
                    <td className="whitespace-pre-line px-4 py-4 text-sm text-gray-500 dark:text-gray-400">
                      {cookie.cookieDescription}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookieTable;
