import clsx from "clsx";

const MessageBox = ({
  children,
  className,
  noMargin,
}: {
  children: React.ReactNode;
  className?: string;
  noMargin?: boolean;
}) => (
  <div
    className={clsx(
      !noMargin && ["my-5 lg:my-8"],
      "rounded-md border border-slate-200 bg-white px-4 py-5 shadow-sm dark:border-slate-700/80 dark:bg-gray-900 sm:px-6 sm:py-8",
      className
    )}
  >
    <div className="flex h-full w-full flex-col items-center justify-center gap-2 text-center text-sm sm:text-base md:items-start md:text-left">
      {children}
    </div>
  </div>
);

const Header = ({
  children,
  Icon,
  className,
  size = "normal",
}: {
  children: React.ReactNode;
  Icon?: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  className?: string;
  size?: "normal" | "small";
}) => (
  <div
    className={clsx(
      "mb-3 flex flex-col items-center gap-3 md:flex-row md:gap-3",
      className
    )}
  >
    {Icon && (
      <div className="">
        <Icon className="h-8 w-8 text-orange-500 md:-mt-1" />
      </div>
    )}
    <h3
      className={clsx(
        "font-semibold text-slate-900 dark:text-slate-200",
        size === "small" && "text-base leading-5 md:text-lg md:leading-6",
        size === "normal" && "text-xl md:text-2xl"
      )}
    >
      {children}
    </h3>
  </div>
);

export default Object.assign(MessageBox, { Header });
