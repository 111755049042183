import { httpsCallable } from "firebase/functions";
import { useRouter } from "next/router";

import { ContentLimit } from "@jurahilfe/shared/types";
import { Button } from "@jurahilfe/shared/components";

import useStore from "@hooks/useStore";
import { functions } from "@utils/firebase/firebase-init";
import { getIsProBlocked } from "@utils/getIsProBlocked";

/**
 * Button that opens the upgrade modal and automatically hides if the user is already pro.
 * Refreshes custom claims on click.
 * Note: Reqires the user to be logged in to show.
 */
const UpgradeButton = ({
  onClick,
  className,
  topicKey,
  proPlus,
  shortened,
}: {
  onClick?: () => void;
  className?: string;
  // For the moment content keys are not handled specifically - could be used to show differnt texts or link directly to checkout pages
  topicKey?: ContentLimit;
  proPlus?: boolean;
  shortened?: boolean;
}) => {
  const router = useRouter();
  const { refreshCustomClaims, user } = useStore((state) => ({
    refreshCustomClaims: state.refreshCustomClaims,
    user: state.user,
  }));

  const handleClick = () => {
    if (process.env.NODE_ENV !== "development") {
      // Fire-and-forget approach to log upgrade clicks
      const callWebhook = httpsCallable(functions, "callable-callMakeWebhook");
      callWebhook({ email: user.email })
        .then(() => console.log("Webhook called!"))
        .catch((error) => console.error(error));
    }

    onClick && onClick();
  };

  // Hide if user is not logged in)
  if (!user) return null;

  // Hide if user is already pro
  if (!getIsProBlocked(true, user, topicKey)) return null;

  return (
    <div className={className}>
      <Button
        size="small"
        type="upgrade"
        onClick={() => {
          // Refresh custom claims
          refreshCustomClaims();
          router.push("/upgrade");
          handleClick();
        }}
      >
        {shortened
          ? "Upgrade"
          : user.trialEligible
          ? "Jetzt kostenlos testen"
          : proPlus
          ? "Pro Plus freischalten"
          : "Pro freischalten"}
      </Button>
    </div>
  );
};

export default UpgradeButton;
