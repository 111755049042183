import { motion } from "framer-motion";

const variants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
};

const RevealContainer = ({
  className,
  ...rest
}: {
  className?: string;
  children: React.ReactNode;
}) => (
  <motion.div
    variants={variants}
    initial="hidden"
    whileInView="visible"
    transition={{ duration: 0.5, delay: 0.2 }}
    viewport={{ once: true }}
    className={className}
    {...rest}
  ></motion.div>
);

export default RevealContainer;
