import React, { useEffect, useRef, useState } from "react";

import { useRouter } from "next/router";
import { useKey } from "react-use";
import {
  AcademicCapIcon,
  AdjustmentsVerticalIcon,
  ArrowRightStartOnRectangleIcon,
  DocumentPlusIcon,
  HomeIcon,
  LockOpenIcon,
  BookOpenIcon,
  SparklesIcon,
  ArrowUpRightIcon,
} from "@heroicons/react/24/solid";

import { Navbar, Annotation } from "@jurahilfe/shared/components";
import { HeaderNavigationItem, UserMenuItem } from "@jurahilfe/shared/types";
import { useMainHeight } from "@jurahilfe/shared/hooks";

import LinkPeek from "@components/shared/LinkPeek";
import UpgradeModal from "@components/app/Upgrade/UpgradeModal";
import UpgradeButton from "@components/app/Upgrade/UpgradeButton";
import OnboardingTrialModal from "@components/app/OnboardingTrialModal";

import Toaster from "@components/shared/Toaster";
import ConnectedModal from "@components/shared/ConnectedModal";
import ContentFeedbackModal from "@components/app/ContentFeedbackModal";

import SearchPalette from "@components/app/SearchPalette";

import { isMac } from "@utils/detectOS";
import useStore from "@hooks/useStore";

import { auth } from "@utils/firebase/firebase-initAuth";
import SearchButton from "./SearchButton";
import { useConditionalHideChatWidget } from "@hooks/useConditionlHideChatWidget";
import { getIsProBlocked } from "@utils/getIsProBlocked";

const baseUrl = process.env.NEXT_PUBLIC_HOMEPAGE_URL;

const appNavigation: HeaderNavigationItem[] = [
  {
    name: "Start",
    href: "/start",
    icon: HomeIcon,
  },
  {
    name: "Lernen",
    href: "/lernen",
    icon: AcademicCapIcon,
  },
  {
    name: "Nachschlagen",
    href: "/nachschlagen",
    icon: BookOpenIcon,
  },
  {
    name: "Ratgeber",
    href: "/ratgeber",
    icon: DocumentPlusIcon,
  },
];

const homepageNavigation: HeaderNavigationItem[] = [
  {
    name: "Vorteile",
    href: `${baseUrl}/#vorteile`,
  },
  {
    name: "Funktionen",
    href: `${baseUrl}/#funktionen`,
  },
  {
    name: "Inhalte",
    href: `${baseUrl}/#inhalte`,
  },
  {
    name: "Preise",
    href: `${baseUrl}/preise`,
  },
  {
    name: "Einzelunterricht",
    href: "https://unterricht.jurahilfe.de",
    icon: ArrowUpRightIcon,
    newTab: true,
  },
];

const userNavigation: UserMenuItem[] = [
  { name: "Einstellungen", href: "/profile", icon: AdjustmentsVerticalIcon },
  { name: "divider" },
  {
    name: "Feedback Formular",
    icon: SparklesIcon,
    href: "https://tally.so/r/mJq9WX",
    highlighted: true,
    newTab: true,
  },
  { name: "divider" },
  {
    name: "Datenschutzerklärung",
    href: "https://www.jurahilfe.de/privacy",
    newTab: true,
  },
  {
    name: "Nutzungsbedingungen",
    href: "https://www.jurahilfe.de/terms",
    newTab: true,
  },
  {
    name: "Impressum",
    href: "https://www.jurahilfe.de/impressum",
    newTab: true,
  },
  { name: "divider" },
  // { name: "Einstellungen", href: "#" },
  {
    name: "Ausloggen",
    icon: ArrowRightStartOnRectangleIcon,
    onClick: async () => {
      const { signOut } = await import("firebase/auth");
      await signOut(auth);
    },
  },
];

// TODO: Handle Admin Navigation Item
const adminNavigation = [
  {
    name: "Admin-Dashboard",
    href: "/admin/dashboard",
    icon: LockOpenIcon,
  },
];

function AppNavigationContainer({ children }: { children: React.ReactNode }) {
  const router = useRouter();
  const mainHeight = useMainHeight();
  const macDetected = isMac();
  useConditionalHideChatWidget();

  const [showUpgradeButton, setShowUpgradeButton] = useState(false);

  const { user, setStore } = useStore((state) => ({
    user: state.user,
    setStore: state.set,
  }));

  useEffect(() => {
    setShowUpgradeButton(!!(user && getIsProBlocked(true, user)));
  }, [user]);

  // Get ref to the main element
  const mainRef = useRef<HTMLDivElement>(null);
  // Reset scroll position on route change
  useEffect(() => {
    if (mainRef.current) {
      console.log("scrolling to top");
      mainRef.current.scrollTop = 0;
    }
    // Listen to changes on asPath to also get triggered by dynamic URLS (otherwise use .pathname)
  }, [router.asPath]);

  // Add listener to CMD + P to toggle search
  useKey(
    (e) => (e.key === "k" && e.metaKey) || (e.key === "k" && e.ctrlKey),
    (e) => {
      if (!user) return;
      e.preventDefault();
      e.stopPropagation();
      setStore((state) => {
        state.searchOpen = !state.searchOpen;
      });
    }
  );

  return (
    <>
      <div className="min-h-full bg-slate-50 dark:bg-slate-800">
        <Navbar
          navigationItems={[
            ...(user ? appNavigation : homepageNavigation),
            ...(user?.claims?.admin ? adminNavigation : []),
          ]}
          type={user ? "app" : "homepage"}
          hideShadow={router.pathname.startsWith("/lernen")}
          userMenuConfig={{
            photoURL: user?.photoURL,
            email: user?.email,
            items: userNavigation,
            extra: showUpgradeButton ? (
              <UpgradeButton className="mx-2" />
            ) : null,
          }}
          extra={
            showUpgradeButton ? (
              <UpgradeButton className="hidden md:block" />
            ) : null
          }
          mobileExtra={showUpgradeButton ? <UpgradeButton /> : null}
          searchButton={
            <Annotation
              content={
                <div className="py-1">
                  <span className="mr-2">Shortcut:</span>
                  <kbd className="keyboard-button">
                    {/* Change symbol based on os */}
                    {macDetected ? "⌘" : "Ctrl"}
                  </kbd>{" "}
                  + <kbd className="keyboard-button">K</kbd>
                </div>
              }
              placement="bottom"
              showDelay={300}
              hideDelay={200}
            >
              <SearchButton />
            </Annotation>
          }
        />

        <main
          id="scroll-nav-container"
          style={{ height: mainHeight }}
          ref={mainRef}
          className="flex-1 overflow-auto bg-gradient-to-br from-slate-50 to-slate-100 dark:bg-gradient-to-br dark:from-slate-900 dark:to-slate-800"
        >
          {children}
        </main>
        <Toaster />
        <ConnectedModal />
        <UpgradeModal />
        <LinkPeek />
        <ContentFeedbackModal />
        <SearchPalette />
        <OnboardingTrialModal />
        {/* <TrialOfferOverlay /> */}
      </div>
    </>
  );
}

export default AppNavigationContainer;
