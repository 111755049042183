import { useCallback } from "react";
import {
  ArrowUpRightIcon,
  InformationCircleIcon,
} from "@heroicons/react/24/outline";
import { useRouter } from "next/router";

import {
  Spinner,
  Annotation,
  Badge,
  Modal,
  Button,
} from "@jurahilfe/shared/components";

import { CardWithRef } from "@types";

import ReaderCard from "@components/app/ReaderPage/ReaderCard";
import MiniBreadcrumbs from "@components/app/SearchPalette/MiniBreadcrumbs";
import ProBadge from "@components/app/Upgrade/ProBadge";
import useStore from "@hooks/useStore";
import { useLinkedCards } from "@hooks/firebase/useLinkedCards";
import { getIsProBlocked } from "@utils/getIsProBlocked";

const LinkPeek = () => {
  const { linkPeek, closeLinkPeek, user } = useStore((state) => ({
    linkPeek: state.linkPeek,
    closeLinkPeek: state.closeLinkPeek,
    user: state.user,
  }));
  const router = useRouter();

  // Get get the document references from the tag
  const [linkedCards, loading] = useLinkedCards(linkPeek.tag);

  const isPro = !getIsProBlocked(true, user);

  const handleOpenContext = (cards: CardWithRef[]) => {
    if (!isPro) {
      handleClose();
      router.push("/upgrade");
      return;
    }
    cards?.forEach((card) => {
      console.log("Card to open", card, card.ref.path);
      // Get topicId, chapterId, deckId and cardId from the card
      const topicId = card.ref.path.split("/")[1];
      const chapterId = card.ref.path.split("/")[3];
      const deckId = card.ref.path.split("/")[5];
      const cardId = card.ref.path.split("/")[7];

      // Open the context
      window.open(
        `/nachschlagen/${topicId}/${chapterId}/${deckId}?cardId=${cardId}`,
        "_blank"
      );
    });
  };

  const handleClose = useCallback(() => {
    closeLinkPeek();
  }, [closeLinkPeek]);

  // Create title (replace _ with space, create plural if more than one card, capitalize first letter)
  const tagDisplay = linkPeek.tag?.replace(/_/g, " ");
  const title = `Referenz${linkedCards?.length > 1 ? "en" : ""} zu "${
    tagDisplay?.charAt(0).toUpperCase() + tagDisplay?.slice(1)
  }"`;

  return (
    <Modal
      key={linkPeek.tag}
      size="large"
      type="link"
      open={linkPeek.open}
      cancelButtonText="Zurück"
      title={
        <div className="flex w-full flex-wrap items-center justify-between gap-x-2">
          <span className="text-base sm:text-lg">{title}</span>
          <Annotation
            className="inline-block cursor-pointer"
            content={
              <div className="font-normal">
                Beachte, dass Verlinkungen aus unterschiedlichen Rechtsgebieten
                und -kontexten stammen können. Wir verlinken bewusst umfassend,
                um mit verschiedenen Perspektiven das Systemverständnis zu
                stärken. Feedback kannst du über den Button rechts unten im
                Lernelement geben.
              </div>
            }
          >
            <Badge className="my-0" type="neutral" size="small">
              <InformationCircleIcon
                className="mr-1 h-4 w-4"
                aria-hidden="true"
              />
              <span className="mr-1">Hinweis</span>{" "}
              <span className="hidden sm:inline">zu Verlinkungen</span>
            </Badge>
          </Annotation>
        </div>
      }
      setClose={handleClose}
      onConfirm={() => {
        // If the user is not pro, open the upgrade modal
        // TODO: Handle cards from different topics (some blocked some now)
        if (!isPro) {
          router.push("/upgrade");
          return;
        }
        handleOpenContext(linkedCards);
      }}
      confirmButtonText={
        <div className="flex items-center gap-x-2 whitespace-nowrap">
          <ArrowUpRightIcon className="h-4 w-4" aria-hidden="true" />
          Alle im Kontext ansehen {!isPro && "(Pro)"}
        </div>
      }
    >
      <div>
        {loading ? (
          <div className="flex h-60 w-full items-center justify-center">
            <Spinner className="h-8 w-8" />
          </div>
        ) : (
          <>
            {linkedCards && linkedCards.length > 1 && (
              <div className="mb-4 rounded-lg bg-slate-50 p-3 text-sm text-slate-700 dark:bg-slate-800 dark:text-slate-300">
                <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
                  <span className="font-medium">
                    {linkedCards.length} Referenzen gefunden
                  </span>
                  <span className="mt-1 text-xs sm:mt-0">
                    Lies verlinkte Inhalte hier oder öffne sie im Kontext
                  </span>
                </div>
              </div>
            )}
            <div className="space-y-5 text-slate-700 dark:text-slate-300">
              {linkedCards
                ?.slice()
                .sort((a, b) => {
                  // Handle lexicographic sortOrder (format like "AAAA", "AAAB", etc.)
                  const aSortOrder = a.sortOrder || "";
                  const bSortOrder = b.sortOrder || "";

                  // Compare strings directly for lexicographic sorting
                  return aSortOrder.localeCompare(bSortOrder);
                })
                .map((card, index) => (
                  <div
                    key={card.id}
                    className="rounded-lg border border-slate-200/50 p-4 shadow-sm dark:border-slate-700/50"
                  >
                    <div className="mb-3 flex flex-wrap items-start gap-x-2">
                      <div className="inline-flex items-center gap-x-1.5">
                        <span className="inline-flex h-5 w-5 flex-shrink-0 items-center justify-center rounded-full bg-slate-100 text-xs font-medium text-slate-800 dark:bg-slate-700/50 dark:text-slate-200">
                          {index + 1}
                        </span>
                        <h3 className="text-sm font-medium leading-tight sm:text-base">
                          {card.title || card.question}
                        </h3>
                      </div>
                      {getIsProBlocked(true, user, card.topicKey) && (
                        <ProBadge className="ml-auto flex-shrink-0" />
                      )}
                    </div>

                    <div
                      onClick={() => handleOpenContext([card])}
                      className="mb-3 cursor-pointer"
                    >
                      <MiniBreadcrumbs
                        size="small"
                        hit={{
                          ...card,
                          objectID: card.id,
                          topicTitle: card.pathInfo.topicTitle,
                          chapterTitle: card.pathInfo.chapterTitle,
                          deckTitle: card.pathInfo.deckTitle,
                          firebasePath: card.ref.path,
                        }}
                        showCardTitle={false}
                      />
                    </div>

                    <ReaderCard
                      key={card.id}
                      card={card}
                      highlightTag={linkPeek.tag}
                    />

                    <div className="mt-3 flex justify-end">
                      <Button
                        type="tertiary"
                        className="whitespace-nowrap"
                        width="auto"
                        size="small"
                        Icon={ArrowUpRightIcon}
                        onClick={() => handleOpenContext([card])}
                      >
                        <span className="whitespace-nowrap">
                          Im Kontext ansehen
                        </span>
                      </Button>
                    </div>
                  </div>
                ))}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default LinkPeek;
