import { firestore } from "./firebase-init";
import { addDoc, collection } from "firebase/firestore";

export interface ContentFeedbackFormData {
  feedbackType: FeedbackType;
  feedback: string;
}

export type FeedbackType = "mistake" | "suggestion" | "understanding" | "other";
export type FeedbackItemType = "card" | "question" | "explanation" | "video";

export interface ContentFeedbackData extends ContentFeedbackFormData {
  userId: string;
  userEmail: string;
  itemType: FeedbackItemType;
  reference: {
    deckId: string;
    chapterId: string;
    topicId: string;
    step: 1 | 2 | 3 | 4;
    itemId: string;
    cardTitle?: string;
    question: string;
    subDirectory: string;
  };
}

export interface ContentFeedbackFirebase extends ContentFeedbackData {
  createdTimestamp: number;
}

export const saveContentFeedback = async (
  feedbackData: ContentFeedbackData
) => {
  try {
    // Check if a session for that deck for today for that user already exists
    await addDoc(collection(firestore, "contentFeedback"), {
      ...feedbackData,
      createdTimestamp: new Date().getTime(),
      date: new Date().toISOString().split("T")[0],
    });
    return true;
  } catch (error) {
    console.error("Error saving study session:", error);
    throw error;
  }
};
