import { Fragment, useCallback, useEffect, useState, useRef } from "react";
import clsx from "clsx";
import {
  Dialog,
  DialogPanel,
  Transition,
  TransitionChild,
} from "@headlessui/react";
import { useKey, useLocalStorage, useMedia } from "react-use";
import { useRouter } from "next/router";
import { BookOpenIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import {
  ChevronRightIcon,
  ChevronLeftIcon,
  DocumentIcon,
} from "@heroicons/react/24/outline";
import { debounce } from "lodash";

import { Button, Spinner } from "@jurahilfe/shared/components";

import ProBlockedDisplay from "@components/app/Upgrade/ProBlockedDisplay";
import ProBadge from "@components/app/Upgrade/ProBadge";
import { performSearch, SearchHit } from "./search";
import HTMLText from "./HTMLText";
import MiniBreadcrumbs from "./MiniBreadcrumbs";

import useStore from "@hooks/useStore";
import { isMac } from "@utils/detectOS";
import { getIsProBlocked } from "@utils/getIsProBlocked";
import { completeOnboardingStep } from "@utils/firebase/completeOnboardingStep";

const SearchPalette = () => {
  const router = useRouter();
  const { open, set, user } = useStore((state) => ({
    open: state.searchOpen,
    set: state.set,
    user: state.user,
  }));

  const isSM = useMedia("(max-width: 640px)", true);
  const mac = isMac();

  // Create a state for the controlled search input
  const [query, setQuery] = useState("");

  const [loading, setLoading] = useState(false);

  const [hits, setHits] = useState<SearchHit[]>([]);
  const [recentHits, setRecentHits] = useLocalStorage<SearchHit[]>(
    "recentHits",
    []
  );

  const [resultsDisplayed, setResultsDisplayed] = useState<SearchHit[]>([]);
  const [activeResult, setActiveResult] = useState<SearchHit>(null);
  const [activeResultBlocked, setActiveResultBlocked] = useState(false);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        inputRef.current?.focus();
      }, 50);
    }
  }, [open]);

  useEffect(() => {
    if (getIsProBlocked(!activeResult?.isTrial, user, activeResult?.topicKey)) {
      setActiveResultBlocked(true);
    } else {
      setActiveResultBlocked(false);
    }
  }, [activeResult, user]);

  useEffect(() => {
    if (query) {
      setResultsDisplayed(hits);
    } else {
      setResultsDisplayed(recentHits);
    }
  }, [hits, recentHits, query]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSearch = useCallback(
    debounce(async function (query) {
      setQuery(query);
      // LIVE SEARCH
      setLoading(true);
      const results = await performSearch(query);
      setHits(results);
      setLoading(false);

      completeOnboardingStep("search-function");
      // DEV SEARCH, using placeholder results
      // if (query) {
      //   setHits(sampleResults);
      // } else {
      //   setHits([]);
      // }
    }, 300),
    []
  );

  const scrollToActiveResult = (block: "start" | "end") => {
    const activeResultElement = document.querySelector(".search-result.active");
    if (activeResultElement) {
      activeResultElement.scrollIntoView({
        behavior: "smooth",
        block: block,
      });
    }
  };

  const setOpen = (open) => {
    set((state) => {
      state.searchOpen = open;
    });
  };

  useKey(
    "ArrowDown",
    (e) => {
      e.preventDefault();
      const index = resultsDisplayed.indexOf(activeResult);
      if (index < resultsDisplayed.length - 1) {
        setActiveResult(resultsDisplayed[index + 1]);
      }
      scrollToActiveResult("start");
    },
    {},
    [activeResult, resultsDisplayed]
  );

  const handleInputChange = (event) => {
    const value = event.target.value;
    debouncedSearch(value);
  };

  // Open deck in study mode
  const handleOpenModule = (hit) => {
    // Get topicId, chapterId and deckId from hit path
    const pathParts = hit.firebasePath.split("/");
    const topicId = pathParts[1];
    const chapterId = pathParts[3];
    const deckId = pathParts[5];
    router.push(`/lernen/${topicId}/${chapterId}/${deckId}`);
    setOpen(false);
  };

  // Open deck in reader mode
  const handleOpenReader = (hit) => {
    // Add to recent hits. If it's already in the list, remove it first to move it to the top.
    // If the list is longer than 10, remove the last item.
    const newRecentHits = recentHits.filter((item) => item.id !== hit.id);
    newRecentHits.unshift(hit);
    if (newRecentHits.length > 5) {
      newRecentHits.pop();
    }

    // Got to link in new tab
    // Get topicId, chapterId, deckId, cardId from hit path
    const pathParts = hit.firebasePath.split("/");
    // Extract topicId, chapterId, deckId and cardId from path in one line
    const topicId = pathParts[1];
    const chapterId = pathParts[3];
    const deckId = pathParts[5];
    const cardId = pathParts[7];
    router.push(
      `/nachschlagen/${topicId}/${chapterId}/${deckId}?cardId=${cardId}`
    );

    setRecentHits(newRecentHits);
    setOpen(false);
  };

  useKey(
    "ArrowUp",
    (e) => {
      e.preventDefault();
      const index = resultsDisplayed.indexOf(activeResult);
      if (index > 0) {
        setActiveResult(resultsDisplayed[index - 1]);
      }
      scrollToActiveResult("end");
    },
    {},
    [activeResult, resultsDisplayed]
  );

  useKey(
    "Enter",
    (e) => {
      if (!open) return;
      console.log("Enter pressed  ");
      // Remove focus from the search input
      e.preventDefault();
      if (activeResult) {
        handleOpenReader(activeResult);
      }
    },
    {},
    [activeResult]
  );

  useEffect(() => {
    // Don't set active result on mobile
    if (isSM) return;
    // Set active result to first result
    if (query && hits.length > 0) {
      setActiveResult(hits[0]);
    } else if (!query && recentHits.length > 0) {
      setActiveResult(null);
    } else {
      setActiveResult(null);
    }
  }, [hits, query, recentHits, isSM]);

  return (
    <Transition
      show={open}
      as={Fragment}
      afterLeave={() => {
        setQuery("");
        setActiveResult(null);
      }}
      appear
    >
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-slate-500 bg-opacity-50 backdrop-blur-sm transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 overflow-y-auto p-2 pt-36 sm:px-6 sm:pb-6 md:px-20 md:pb-20">
          <TransitionChild
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <DialogPanel className="search-result-display mx-auto max-w-4xl transform divide-y divide-slate-100 overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all dark:divide-slate-600 dark:bg-slate-800">
              <div className="relative">
                <>
                  <div className="relative border-b border-slate-100 dark:border-slate-700">
                    <MagnifyingGlassIcon
                      className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-slate-400 dark:text-slate-500"
                      aria-hidden="true"
                    />
                    <input
                      ref={inputRef}
                      className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-slate-900 placeholder:text-slate-400 focus:outline-none focus:ring-0 dark:text-slate-200  dark:placeholder:text-slate-500 sm:text-sm"
                      placeholder="Inhalte durchsuchen..."
                      onChange={handleInputChange}
                    />
                  </div>

                  <div className="flex divide-x divide-slate-100 dark:divide-slate-700">
                    <div
                      className={clsx(
                        activeResult && "hidden sm:block",
                        "max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4 sm:max-h-128",
                        activeResult && "sm:h-128"
                      )}
                    >
                      {/* No results */}
                      {query !== "" && hits.length === 0 && (
                        <div className="px-6 py-14 text-center text-sm sm:px-14">
                          {loading ? (
                            <div className="flex flex-col items-center justify-center">
                              <Spinner className="h-6 w-6 text-slate-400 dark:text-slate-400" />
                            </div>
                          ) : (
                            <>
                              <DocumentIcon
                                className="mx-auto h-6 w-6 text-slate-400 dark:text-slate-400"
                                aria-hidden="true"
                              />
                              <p className="mt-4 font-semibold text-slate-900 dark:text-slate-200">
                                Keine Treffer
                              </p>
                              <p className="mt-2 text-slate-500 dark:text-slate-400">
                                Versuchen Sie es mit einem anderen Suchbegriff.
                              </p>
                            </>
                          )}
                        </div>
                      )}
                      {/* Heading */}
                      {query === "" &&
                        (recentHits.length > 0 ? (
                          <h2 className="mb-4 mt-2 text-xs font-semibold text-slate-500 dark:text-slate-400">
                            Letzte Treffer
                          </h2>
                        ) : (
                          <div className="px-6 py-14 text-center text-sm sm:px-14">
                            <MagnifyingGlassIcon
                              className="mx-auto h-6 w-6 text-slate-400"
                              aria-hidden="true"
                            />
                            <p className="mt-4 font-semibold text-slate-900 dark:text-slate-200">
                              Suche nach Inhalten
                            </p>
                            <p className="mt-2 text-slate-500">
                              Gib einen Suchbegriff ein, um nach Inhalten zu
                              suchen.
                            </p>
                          </div>
                        ))}
                      {/* Result list */}
                      <div
                        className={clsx(
                          "-mx-2 space-y-1 text-sm text-slate-700 dark:text-slate-400"
                        )}
                      >
                        {resultsDisplayed.map((hit) => {
                          const active = activeResult?.id === hit.id;
                          return (
                            <div
                              key={hit.id}
                              className={clsx(
                                "search-result",
                                "group",
                                "flex cursor-default select-none items-center rounded-md p-2 py-2.5",
                                active && "active",
                                active &&
                                  "bg-slate-100 text-slate-900 dark:bg-slate-600 dark:text-slate-200",
                                !active &&
                                  "hover:bg-slate-50 dark:hover:bg-slate-700"
                              )}
                              onClick={() => {
                                setActiveResult(hit);
                              }}
                            >
                              <>
                                <DocumentIcon className="h-5 w-5 flex-none text-slate-500 dark:text-slate-400" />
                                <div className="two-line-truncate relative flex-auto">
                                  <div className="flex flex-col">
                                    <span
                                      className={clsx(
                                        active
                                          ? "mr-0 text-slate-800 dark:text-white"
                                          : "mr-8 text-slate-900 dark:text-slate-200",
                                        "two-line-truncate ml-3 flex-auto font-semibold "
                                      )}
                                    >
                                      <HTMLText
                                        htmlString={
                                          query
                                            ? hit._highlightResult.question
                                                .value
                                            : hit.question
                                        }
                                      />
                                    </span>
                                    <span className="ml-3 mt-1 flex-auto truncate">
                                      <HTMLText
                                        htmlString={
                                          query
                                            ? hit._highlightResult.title.value
                                            : hit.title
                                        }
                                      />
                                    </span>
                                  </div>
                                  {getIsProBlocked(
                                    !hit.isTrial,
                                    user,
                                    hit.topicKey
                                  ) && (
                                    // Add the blur only to the right third of the element, fading out to the left
                                    <div
                                      className={clsx(
                                        "pointer-events-none absolute inset-y-0 right-0 flex w-full items-center justify-end bg-gradient-to-l to-transparent p-4",
                                        active
                                          ? "from-slate-100 dark:from-slate-600"
                                          : "hover:from-slate-50 dark:from-slate-800 dark:group-hover:from-slate-700"
                                      )}
                                    >
                                      <ProBadge />
                                    </div>
                                  )}
                                </div>
                                {active && (
                                  <ChevronRightIcon
                                    className="ml-3 h-5 w-5 flex-none text-slate-400 dark:text-slate-200"
                                    aria-hidden="true"
                                  />
                                )}
                              </>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    {activeResult && (
                      <div
                        className={clsx(
                          "relative max-h-96 w-full flex-none flex-col divide-y divide-slate-100 overflow-y-auto dark:divide-slate-700 sm:flex sm:h-128 sm:max-h-128 sm:w-1/2"
                        )}
                      >
                        {activeResultBlocked && (
                          <div className="absolute inset-0 z-10 backdrop-blur-sm">
                            <ProBlockedDisplay
                              title={activeResult.chapterTitle}
                              style="slim"
                              subscriptionType={activeResult.topicKey}
                            />
                          </div>
                        )}
                        <div className="sticky top-0 flex items-center justify-end gap-2 border-none bg-white bg-opacity-50 px-3 py-3 backdrop-blur-sm dark:bg-slate-800 dark:bg-opacity-50 sm:hidden">
                          <button
                            className="btn-text-default mr-auto flex h-8 w-8 items-center justify-center rounded"
                            onClick={() => setActiveResult(null)}
                          >
                            <ChevronLeftIcon
                              className="h-5 w-5 flex-none text-slate-400"
                              aria-hidden="true"
                            />
                          </button>
                          <Button
                            size="small"
                            onClick={() => handleOpenReader(activeResult)}
                            Icon={BookOpenIcon}
                            width="auto"
                          >
                            Im Kontext ansehen
                          </Button>
                          {/* <Button
                            size="small"
                            type="tertiary"
                            onClick={() => handleOpenModule(activeResult)}
                            Icon={RectangleStackIcon}
                            width="auto"
                          >
                            Modul lernen
                          </Button> */}
                        </div>
                        <div className="flex-none border-none px-6 pb-4 pt-2 text-left text-lg sm:pb-5 sm:pt-7">
                          {/* <MiniBreadcrumbs
                            hit={activeResult}
                            className="mb-1"
                          /> */}
                          <h2 className="text-base font-semibold leading-5 text-slate-900 dark:text-slate-200">
                            <HTMLText
                              htmlString={
                                query
                                  ? activeResult._highlightResult.question.value
                                  : activeResult.question
                              }
                            />
                          </h2>
                          <div className="mt-2 text-sm leading-5 text-slate-800 dark:text-slate-300">
                            <MiniBreadcrumbs
                              hit={activeResult}
                              showCardTitle
                              query={query}
                            />
                          </div>
                        </div>
                        <div className="relative flex flex-auto flex-col justify-between px-6">
                          <p className="prose-answer-text !prose-sm relative">
                            {!activeResultBlocked && (
                              <HTMLText
                                htmlString={
                                  query
                                    ? activeResult._highlightResult.answer.value
                                    : activeResult.answer
                                }
                              />
                            )}
                          </p>
                        </div>
                        <div className="sticky bottom-0 hidden justify-end gap-2 border-none bg-white bg-opacity-50 px-3 py-2 backdrop-blur-sm dark:bg-slate-800 dark:bg-opacity-50 sm:flex">
                          <Button
                            size="small"
                            onClick={() => handleOpenReader(activeResult)}
                            Icon={BookOpenIcon}
                            width="auto"
                          >
                            Im Kontext ansehen
                          </Button>
                          {/* <Button
                            size="small"
                            type="tertiary"
                            onClick={() => handleOpenModule(activeResult)}
                            Icon={RectangleStackIcon}
                            width="auto"
                          >
                            Modul lernen
                          </Button> */}
                        </div>
                      </div>
                    )}
                  </div>
                  {/* Optional: Add improved search and add hints like in https://tailwindui.com/components/application-ui/navigation/command-palettes#component-540bdf1e4a0e2ec2f667a2c7c123ff0f */}
                  <div className="hidden flex-wrap items-center gap-5 bg-gray-50 px-4 py-2.5 text-xs text-gray-700 dark:border-t dark:border-slate-700 dark:bg-slate-800 dark:text-slate-400 sm:flex">
                    <span>
                      <kbd className="keyboard-button mr-1 text-sm">↑↓</kbd>{" "}
                      <span className="sm:hidden">Durchschalten</span>
                      <span className="hidden sm:inline">
                        Ergebnisse durchschalten
                      </span>
                    </span>
                    <span>
                      <kbd className="keyboard-button mr-1 text-sm">⏎</kbd> Im
                      Kontext ansehen
                    </span>
                    <span>
                      <kbd className="keyboard-button mr-1 py-1 text-xs">
                        {mac ? "⌘" : "Strg"}
                      </kbd>
                      +
                      <kbd className="keyboard-button mx-1 py-1 text-xs">K</kbd>
                      Suche öffnen / schließen
                    </span>
                  </div>
                </>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SearchPalette;
