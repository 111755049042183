import { COOKIE_OPTIONS } from "./cookies";

// Theme cookie name using the same domain config as other cookies
export const THEME_COOKIE_NAME = "theme-preference";

export const THEME_PROVIDER_CONFIG = {
  attribute: "class",
  defaultTheme: "light",
  enableSystem: true,
  // Use cookies for storage to share across domains
  storageKey: THEME_COOKIE_NAME,
  cookie: {
    ...COOKIE_OPTIONS,
    // next-themes specific options if needed
  },
};

// Helper to check system preference
export const getSystemTheme = () =>
  typeof window !== "undefined"
    ? window.matchMedia("(prefers-color-scheme: dark)").matches
      ? "dark"
      : "light"
    : "dark";
