import { create } from "zustand";
import produce from "immer";
import { devtools } from "zustand/middleware";

import {
  StripeProduct,
  ContentLimit,
  ModalType,
  TopicTitle,
} from "@jurahilfe/shared/types";

import {
  Step,
  ExtendedFirebaseUser,
  ActiveDeck,
  StudySession,
  TopicOutline,
} from "@types";

import { DoubleConfirmModalType } from "@components/shared/DoubleConfirmModal";
import { getCustomClaims } from "@utils/firebase/getCustomClaims";
import { FeedbackItemType } from "@utils/firebase/saveContentFeedback";
import { saveStudySession } from "@utils/firebase/saveStudySession";
import { getTopicOutline } from "@utils/firebase/getTopicOutline";
import { completeOnboardingStep } from "@utils/firebase/completeOnboardingStep";

export interface StoreState {
  // Currently not used in the app, but kept for reference
  initialized: boolean;
  modal: {
    title: string;
    isOpen: boolean;
    content: React.ReactNode | null;
    type?: ModalType;
    confirmButtonText?: string;
    cancelButtonText?: string;
    onConfirm?: () => void;
  };
  linkPeek: {
    open: boolean;
    tag?: string;
  };
  stripeProducts: Record<string, StripeProduct>;
  showUpgradeModal: boolean;
  showContentFeedbackModal: {
    open: boolean;
    reference?: {
      itemId: string;
      question: string;
      itemType: FeedbackItemType;
      cardTitle?: string;
    };
  };
  searchOpen: boolean;
  showOnboardingModal: boolean;
  onboardingProgress: { [key: string]: boolean }; // New state for onboarding progress
  activeDeck: ActiveDeck | null;
  studySession: StudySession | null;

  topics: Record<TopicTitle, TopicOutline | null>;
  topicsLoading: boolean;

  doubleConfirmModal: {
    open: boolean;
    title?: string;
    content?: string;
    onConfirm?: () => void;
    type?: DoubleConfirmModalType;
  };
  cookieChoiceUpdated: number;
  cookieDetails: {
    category: string;
    descriptionText: string;
    cookies: {
      cookieName: string;
      cookieDuration: string;
      cookieService: string;
      cookieDescription: string;
    }[];
  }[];
  user: ExtendedFirebaseUser | null;
  analyticScriptIsLoaded?: boolean;
  marketingScriptIsLoaded?: boolean;

  // Delete this
  chatRibbonArray: any[];
  simpleCounter: number;
  nestedState: {
    secondLevel: {
      thirdLevel: string;
    };
  };
}

export interface StoreActions {
  set: (fn: (draft: StoreState) => void) => void;
  setInitialized: (initialized: boolean) => void;
  showUpgradeModal: boolean;
  openModal: ({
    title,
    type,
    content,
    confirmButtonText,
    onConfirm,
    cancelButtonText,
  }: {
    content: React.ReactNode;
    title?: string;
    type?: ModalType;
    confirmButtonText?: string;
    onConfirm?: () => void;
    cancelButtonText?: string;
  }) => void;
  closeModal: () => void;
  setOnboardingProgressForKey: (stepId: string) => void; // New action to set onboarding progress

  closeLinkPeek: () => void;
  openLinkPeek: (tag: string) => void;
  refreshCustomClaims: () => void;
  setActiveDeck: (options: {
    topicId: string;
    chapterId: string;
    deckId: string;
    topicTitle: string;
    chapterTitle: string;
    deckTitle: string;
    isTrial: boolean;
    step: Step;
    topicKey: ContentLimit;
  }) => void;
  clearActiveDeck: () => void;
  startStudySession: (activeDeckId: string) => void;
  startSessionActiveTime: () => void;
  clearSessionActiveTime: () => void;
  logSessionEvent: (
    event: "interactionWithTime" | "timeOnly",
    clearSession?: boolean
  ) => void;
  changeSessionStep: (step: Step) => void;

  initializeTopics: () => void;

  // Delete this
  increaseSimpleCounter: () => void;
  clearSimpleCounter: () => void;
}

export interface UseStore extends StoreState, StoreActions {}

const useStore = create<UseStore>()(
  devtools(
    (set, get) => ({
      // Function to set state
      set: (fn) => set(produce(fn)),
      setInitialized: (value: boolean) => set(() => ({ initialized: value })),
      initialized: false,
      user: null,
      topics: {
        Zivilrecht: null,
        Strafrecht: null,
        "Öffentliches Recht": null,
        "Juristisches Allgemeinwissen": null,
      },
      topicsLoading: false,
      searchOpen: false,
      stripeProducts: {},
      showContentFeedbackModal: {
        open: false,
      },
      showOnboardingModal: false,
      onboardingProgress: {}, // Initialize onboardingProgress
      doubleConfirmModal: {
        open: false,
      },
      modal: {
        isOpen: false,
        title: "",
        content: null,
        type: "info",
      },
      linkPeek: {
        open: false,
      },
      studySession: {
        deckId: null,
        sessionId: null,
        itemsProgress: {
          1: 0,
          2: 0,
          3: 0,
        },
        stepTimes: {
          1: 0,
          2: 0,
          3: 0,
        },
        currentStep: 1,
        deckInfo: null,
        lastActiveTimestamp: null,
      },
      showUpgradeModal: false,
      activeDeck: null,
      cookieChoiceUpdated: 0,
      cookieDetails: [
        {
          category: "Notwendig",
          descriptionText:
            "Notwendige Cookies sind für die grundlegende Funktionalität der Website erforderlich. Diese Cookies sind immer aktiviert.",
          cookies: [
            {
              cookieName: "cookieChoiceDone",
              cookieDuration: "1 Jahr",
              cookieService: "1st Party Cookie",
              cookieDescription:
                "Bestimmt ob der Nutzer die Cookie Auswahl bereits getätigt hat.",
            },
            {
              cookieName: "acceptStatistic",
              cookieDuration: "1 Jahr",
              cookieService: "1st Party Cookie",
              cookieDescription:
                "Bestimmt ob der Nutzer der Verwendung von Statistik Cookies zugestimmt hat.",
            },
            {
              cookieName: "acceptMarketing",
              cookieDuration: "1 year",
              cookieService: "1st Party Cookie",
              cookieDescription:
                "Bestimmt ob der Nutzer der Verwendung von Marketing Cookies zugestimmt hat.",
            },
            {
              cookieName: "acceptExternalMedia",
              cookieDuration: "1 year",
              cookieService: "1st Party Cookie",
              cookieDescription:
                "Bestimmt ob der Nutzer der Verwendung von externen Medien Cookies zugestimmt hat.",
            },
            {
              cookieName: "_GRECAPTCHA",
              cookieDuration: "90 Tage",
              cookieService: "Google Recaptcha",
              cookieDescription:
                "Notwendig um die Google Recaptcha zu verwenden.",
            },
          ],
        },
        {
          category: "Statistik",
          descriptionText:
            "Statistik-Cookies helfen uns zu verstehen, wie unsere Besucher unsere Website nutzen. Anhand dieser Informationen können wir die Leistung unserer Website verbessern und die Benutzererfahrung für Sie verbessern.",
          cookies: [
            {
              cookieName: "_hp2_id.APP_ID",
              cookieDuration: "13 Monate",
              cookieService: "Heap.io",
              cookieDescription:
                "User-Cookie (speichert user_id, und andere ids)",
            },
            {
              cookieName: "_hp2_ses_props.APP_ID",
              cookieDuration: "30 Minuten",
              cookieService: "Heap.io",
              cookieDescription:
                "Session-Properties-Cookie (speichert Timestamp und Cookie-Domain/Pfad)",
            },
            {
              cookieName: "_hp2_props.APP_ID",
              cookieDuration: "13 Monate",
              cookieService: "Heap.io",
              cookieDescription:
                "Event-Properties-Cookie (speichert Properties der addEventProperties API)",
            },
            {
              cookieName: "_hp2_hld.",
              cookieDuration: "Sollte nicht bestehen bleiben",
              cookieService: "Heap.io",
              cookieDescription:
                "Wird verwendet um zu bestimmen, auf welchem Domain ein Cookie gesetzt werden kann (da Public Suffix Domains das Setzen von Cookies auf der obersten Ebene blockieren)",
            },
            // {
            //   cookieName: "_ga",
            //   cookieDuration: "2 years",
            //   cookieService: "Google Analytics",
            //   cookieDescription: "Used to distinguish users.",
            // },
            // {
            //   cookieName: "_gid",
            //   cookieDuration: "24 hours",
            //   cookieService: "Google Analytics",
            //   cookieDescription: "Used to distinguish users.",
            // },
            // {
            //   cookieName: "_ga_<container-id>",
            //   cookieDuration: "2 years",
            //   cookieService: "Google Analytics",
            //   cookieDescription: "Used to persist session state.",
            // },
            // {
            //   cookieName: "_gac_gb_<container-id>",
            //   cookieDuration: "90 days",
            //   cookieService: "Google Analytics",
            //   cookieDescription: "Contains campaign related information.",
            // },
            {
              cookieName: "APISID, HSID, SAPISID, SID, SSID",
              cookieDuration: "2 years",
              cookieService: "Google Recaptcha",
              cookieDescription:
                "These Google security cookies help to authenticate the user, prevent the fraudulent use of login information and protect user data from unauthorized access.",
            },
          ],
        },
        {
          category: "Marketing",
          descriptionText:
            "Marketing-Cookies werden verwendet, um Besuchern auf Websites zu folgen. Die Absicht ist, Anzeigen zu zeigen, die relevant und ansprechend für den einzelnen Benutzer sind und daher wertvoller für Publisher und werbetreibende Drittparteien sind.",
          cookies: [
            {
              cookieName: "_fbp",
              cookieDuration: "3 Monate",
              cookieService: "Meta Pixel",
              cookieDescription:
                "Wird von Meta verwendet, um eine Reihe von Werbeprodukten anzubieten, zum Beispiel Echtzeitgebote von Werbetreibenden.",
            },
            {
              cookieName: "fr",
              cookieDuration: "3 Monate",
              cookieService: "Meta Pixel",
              cookieDescription:
                "Wird von Meta für Werbe- und Tracking-Zwecke verwendet.",
            },
          ],
        },
      ],

      // Delete the following
      // Array that contains the opened chats from the user
      chatRibbonArray: [],

      // Example state variable in the State Management Example page
      simpleCounter: 0,

      // NOTE: Currently not used in the app, but kept for reference
      // TODO: Adjust to improve performance; user progress is not directly available in forTopics, forChapters and forDecks
      initializeTopics: async () => {
        const topicsToInitialize: TopicTitle[] = [
          "Zivilrecht",
          "Strafrecht",
          "Öffentliches Recht",
          "Juristisches Allgemeinwissen",
        ];

        const userId = get().user?.uid;
        if (!userId) {
          console.warn(
            "Initializing topics without a user ID. User Progress will not be loaded."
          );
        }

        // Set the loading state to true
        set(() => ({
          topicsLoading: true,
        }));

        const outlinePromises = topicsToInitialize.map((topic) =>
          getTopicOutline(topic as TopicTitle, userId)
        );
        const topics = await Promise.all(outlinePromises);

        // Combine the topics with the topicsToInitialize array and prepare the new state object
        const newTopics = topics.reduce((acc, topic, index) => {
          acc[topicsToInitialize[index]] = topic;
          return acc;
        }, {} as Record<TopicTitle, TopicOutline | null>);

        set(() => ({
          topics: newTopics,
          topicsLoading: false,
        }));
      },

      // Optional: Add a function to start listeners to the users progress in the topics
      // The function will keep updating the topics with the user's progress whenever the data changes
      // Starting the listeners is optional, if the initial snapshot of the data with the users progress is not enough

      // Inside your store definition
      setActiveDeck: ({
        topicId,
        chapterId,
        deckId,
        chapterTitle,
        topicTitle,
        deckTitle,
        isTrial,
        step,
      }) =>
        set(() => {
          // Set the new active deck
          return {
            activeDeck: {
              topicId,
              chapterId,
              id: deckId,
              topicTitle,
              chapterTitle,
              title: deckTitle,
              isTrial,
              step,
            },
          };
        }),
      changeSessionStep: async (step) => {
        // Record the interaction before changing the step
        await get().logSessionEvent("timeOnly");

        set((state) => {
          // If no active deck, or no study session, do nothing
          if (!state.activeDeck || !state.studySession) {
            console.error(
              "Cannot change session step without an active deck or study session"
            );
            return;
          }
          // Update the current step in the study session
          return {
            studySession: {
              ...state.studySession,
              currentStep: step,
            },
            activeDeck: {
              ...state.activeDeck,
              step,
            },
          };
        });
      },
      clearActiveDeck: async () => {
        // Log the time spent in the session before clearing the active deck
        await get().logSessionEvent("timeOnly", true);

        set(() => {
          return {
            activeDeck: null,
            // Reset the study session when the active deck is cleared
            studySession: null,
          };
        });
      },

      startStudySession: async (activeDeckId: string) => {
        // Double check if the active deck that triggered the start of the session is also the current active deck
        if (activeDeckId !== get().activeDeck?.id) {
          console.error(
            "The active deck that triggered the start of a study session is not the current active deck"
          );
          return;
        }

        await get().logSessionEvent("timeOnly");

        // Check off onboarding step
        await completeOnboardingStep("start-session");
      },
      // Log the user's interaction with the current study session
      logSessionEvent: async (event, clearSession) => {
        const { activeDeck, studySession, user } = get();

        if (!activeDeck || !user) {
          console.error(
            "Cannot increase session progress without an active deck, study session or user",
            {
              activeDeck,
              user,
              event,
              clearSession,
            }
          );
          return;
        }

        if (clearSession) {
          console.log("🔴 Clearing the session");
        }

        try {
          const updatedSession = await saveStudySession(
            {
              lastActiveTimestamp:
                studySession?.lastActiveTimestamp || Date.now(),
              activeDeck,
              userId: user.uid,
            },
            { addProgressCount: event === "interactionWithTime" }
          );

          const updatedSessionData = {
            ...studySession,
            ...updatedSession,
          };

          console.log("++ Updated session", updatedSessionData);
          set(() => ({
            studySession: clearSession ? null : updatedSessionData,
          }));
        } catch (error) {
          console.error("Error updating the session progress", error);
        }
      },
      startSessionActiveTime: () =>
        set((state) => {
          const currentTimestamp = Date.now();
          return {
            studySession: {
              ...state.studySession,
              lastActiveTimestamp: currentTimestamp,
            },
          };
        }),
      clearSessionActiveTime: () =>
        set((state) => {
          return {
            studySession: {
              ...state.studySession,
              lastActiveTimestamp: null,
            },
          };
        }),
      refreshCustomClaims: async () => {
        const updatedClaims = await getCustomClaims();
        if (!updatedClaims) return;
        set((state) => ({
          user: {
            ...state.user,
            claims: updatedClaims,
          },
        }));
      },
      closeModal: () =>
        set((state) => ({
          modal: {
            // Keep the title, content and type of the modal when it is closed to prevent dissapearing content while animating closed
            ...state.modal,
            isOpen: false,
          },
        })),
      closeLinkPeek: () =>
        set(() => ({
          linkPeek: {
            tag: undefined,
            open: false,
          },
        })),
      openLinkPeek: (tag) => {
        completeOnboardingStep("follow-link");
        set(() => ({
          linkPeek: {
            tag,
            open: true,
          },
        }));
      },
      // Function to set onboarding progress
      setOnboardingProgressForKey: (stepId) => {
        const currentProgress = get().onboardingProgress;
        if (!currentProgress[stepId]) {
          set(() => ({
            onboardingProgress: {
              [stepId]: true,
            },
          }));
        }
      },
      openModal: ({
        title,
        content,
        type,
        confirmButtonText,
        onConfirm,
        cancelButtonText,
      }) =>
        set(() => ({
          modal: {
            isOpen: true,
            content: content || "Inhalt nicht gefunden",
            type: type || "info",
            title: title
              ? title
              : type === "error"
              ? "Fehler"
              : type === "success"
              ? "Erfolg"
              : "Information",
            confirmButtonText,
            onConfirm,
            cancelButtonText,
          },
        })),

      // Delete this
      increaseSimpleCounter: () =>
        set((state) => ({ simpleCounter: state.simpleCounter + 1 })),
      clearSimpleCounter: () => set({ simpleCounter: 0 }),
      nestedState: { secondLevel: { thirdLevel: "Hard to get!" } },
    }),
    { name: "MainStore" }
  )
);

export default useStore;
