import "@styles/globals.css";
//import drawer css
import "react-modern-drawer/dist/index.css";

import { useEffect, useState } from "react";
import Head from "next/head";
import Script from "next/script";
import { ThemeProvider } from "next-themes";
import { Inter } from "@next/font/google";

import {
  Logo,
  AnalyticsProvider,
  CookieBanner,
} from "@jurahilfe/shared/components";

import { THEME_PROVIDER_CONFIG } from "@jurahilfe/shared/config";

import DoubleConfirmModal from "@components/shared/DoubleConfirmModal";

import { useMigrations } from "@hooks/firebase/useMigrations";
import { useInitializeStore } from "@hooks/useInitializeStore";
import AppNavigationContainer from "@components/layout/AppNavigationContainer";

const inter = Inter({
  subsets: ["latin"],
  variable: "--font-inter",
});

function MyApp({ Component, pageProps }) {
  const isInitialized = useInitializeStore();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useMigrations(isInitialized);

  // Render the loading state only on client-side
  if (!isInitialized && isMounted) {
    return (
      <div className="flex h-screen flex-col items-center justify-center gap-4 bg-white text-gray-600 dark:bg-gray-900 dark:text-gray-400">
        {/* Animate an infinite spinner that starts slow turns very fast and slows down again with framer motion */}
        <div className="animate-pulse">
          <Logo size="large" />
        </div>
      </div>
    );
  }

  return (
    <>
      <Head>
        <title>Deine interaktive Lernplattform</title>
        <meta
          name="description"
          content="Jura-Training kompakt, verlinkt und interaktiv."
        />
        <link
          rel="apple-touch-icon"
          sizes="76x76"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <meta name="theme-color" content="#0f172a"></meta>
        <meta charSet="utf-8" />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />

        {/* Social sharing images */}
        <meta property="og:url" content={process.env.NEXT_PUBLIC_APP_URL} />
        <meta property="og:title" content="Jurahilfe.de | Lerne einfach Jura" />
        <meta
          property="og:description"
          content="Jura-Training kompakt, verlinkt und interaktiv."
        />
        <meta
          property="og:image"
          content={`${process.env.NEXT_PUBLIC_APP_URL}/sharing-image.png`}
        />
        <meta property="og:type" content="website" />

        {/* Twitter */}
        <meta name="twitter:card" content="summary" />
        <meta
          name="twitter:text:title"
          content="Jurahilfe.de | Lerne einfach Jura"
        />
        <meta
          name="twitter:description"
          content="Jura-Training kompakt, verlinkt und interaktiv."
        />
        <meta
          name="twitter:image"
          content={`${process.env.NEXT_PUBLIC_APP_URL}/sharing-image.png`}
        />
      </Head>
      <Script strategy="lazyOnload" id="sib-conversations-script">
        {`(function(d, w, c) {
                w.SibConversationsID = '636bd4546383d8497e2d0abd';
                w[c] = w[c] || function() {
                    (w[c].q = w[c].q || []).push(arguments);
                };
                var s = d.createElement('script');
                s.async = true;
                s.src = 'https://conversations-widget.sendinblue.com/sib-conversations.js';
                if (d.head) d.head.appendChild(s);
            })(document, window, 'SibConversations');`}
      </Script>
      <style jsx global>{`
        html {
          font-family: ${inter.style.fontFamily};
        }
      `}</style>

      <ThemeProvider {...THEME_PROVIDER_CONFIG}>
        <AnalyticsProvider
          config={{
            privacyPolicyLink: "https://www.jurahilfe.de/privacy",
            plausibleDomain: "jurahilfe.de,rep.jurahilfe.de",
            metaPixelId: process.env.NEXT_PUBLIC_META_PIXEL_ID,
            heapId: process.env.NEXT_PUBLIC_HEAP_ANALYTICS_ID,
          }}
        >
          <AppNavigationContainer>
            <Component {...pageProps} />
          </AppNavigationContainer>
          {/* TODO: Move to AppNavigationContainer? */}
          <DoubleConfirmModal />
          <CookieBanner
            privacyPolicyLink="https://www.jurahilfe.de/privacy"
            showStatistic={true}
            showMarketing={true}
            showExternalMedia={false}
          />
        </AnalyticsProvider>
      </ThemeProvider>
    </>
  );
}

export default MyApp;
