import { useRouter } from "next/router";
import clsx from "clsx";

import { Button, Logo, YoutubeEmbed } from "@jurahilfe/shared/components";
import { ContentLimitWithProPlus } from "@types";

import MessageBox from "@components/app/MessageBox";
import UpgradeButton from "./UpgradeButton";
import { getBadgeContent } from "./ProBadge";

const ProBlockedDisplay = ({
  title,
  style,
  subscriptionType,
}: {
  title?: string;
  style?: "slim" | "normal";
  subscriptionType?: ContentLimitWithProPlus;
}) => {
  const router = useRouter();
  const badgeContent = getBadgeContent(subscriptionType);

  return (
    <div className="flex h-full w-full items-center justify-center px-4">
      <MessageBox className="relative mx-2 w-full max-w-xl bg-opacity-70 dark:bg-opacity-70">
        <MessageBox.Header
          Icon={Logo as any}
          size={style === "slim" ? "small" : "normal"}
        >
          <span className="text-premium">{badgeContent}</span>{" "}
          <span className="font-normal">Inhalt{title && ":"} </span>
          {title}
        </MessageBox.Header>
        <div className="relative w-full">
          <div
            className={clsx(
              "mb-4 max-w-prose text-sm md:text-base",
              style === "slim" && "text-sm"
            )}
          >
            Dieser und weitere Inhalte sind im{" "}
            {subscriptionType ? `${badgeContent}` : "Pro"} Abo
            {subscriptionType === "zr" ||
            subscriptionType === "strr" ||
            subscriptionType === "oeffr"
              ? " und im Pro Plus Abo "
              : subscriptionType === "jaw"
              ? " als Bonusinhalt "
              : " "}
            enthalten.
          </div>
          <div className="mb-4 sm:mb-8">
            <YoutubeEmbed
              embedId="mkFRKPgCKhg"
              embedSettings={{
                cc_load_policy: false,
              }}
            />
          </div>
          <div className="my-2 flex w-full items-center justify-center gap-2">
            <Button
              className="flex-1"
              type="tertiary"
              size="small"
              onClick={() => {
                router.push("/upgrade");
              }}
            >
              Mehr erfahren
            </Button>
            <UpgradeButton className="flex-1 whitespace-nowrap" />
          </div>
        </div>
      </MessageBox>
    </div>
  );
};

export default ProBlockedDisplay;
