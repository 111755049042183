import React from "react";
import clsx from "clsx";
import { StarIcon } from "@heroicons/react/24/solid";

interface StarRatingProps {
  rating?: number;
  maxRating?: number;
  showText?: boolean;
  textBefore?: string;
  textAfter?: string;
  className?: string;
  starSize?: "xs" | "sm" | "md" | "lg";
}

const StarRating: React.FC<StarRatingProps> = ({
  rating = 4.9,
  maxRating = 5,
  showText = true,
  textBefore = "",
  textAfter = "aus über 60 Google-Bewertungen",
  className = "",
  starSize = "md",
}) => {
  const getStarSize = (): string => {
    switch (starSize) {
      case "xs":
        return "h-3 w-3";
      case "sm":
        return "h-4 w-4";
      case "lg":
        return "h-6 w-6";
      case "md":
      default:
        return "h-5 w-5";
    }
  };

  const stars = Array.from({ length: maxRating }, (_, i) => i + 1);

  return (
    <div
      className={clsx(
        "flex",
        "flex-col sm:flex-row",
        "items-start gap-x-2 gap-y-1 sm:items-center",
        className
      )}
    >
      <div className="flex">
        {stars.map((star) => (
          <StarIcon
            key={star}
            className={`${getStarSize()} flex-shrink-0 text-yellow-400`}
            aria-hidden="true"
          />
        ))}
      </div>
      {showText && (
        <p
          className={`ml-0.5 text-sm font-medium text-slate-600 dark:text-slate-300`}
        >
          {textBefore && <span>{textBefore} </span>}
          <span className="font-bold">
            {rating} von {maxRating} Sternen
          </span>{" "}
          {textAfter && textAfter}
        </p>
      )}
    </div>
  );
};

export default StarRating;
