import { useCallback } from "react";
import clsx from "clsx";
import {
  CheckIcon,
  ChevronRightIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Transition,
} from "@headlessui/react";

import { PricingTier as PricingTierType } from "../../config/pricingTiers";

import Button from "../Button";
import Logo from "../Logo";

import { PriceFrequency, PricingPageType } from "./PricingHighlights";

const getTrialMessage = (
  tier: PricingTierType,
  frequency: PriceFrequency,
  trialEligible?: boolean
) => {
  const trialDays = tier.price?.[frequency.value]?.trialPeriodDays;
  if (!trialEligible || !trialDays) return `${tier.subtitle} freischalten`;
  return `${trialDays} Tage kostenlos testen`;
};

const PricingTier = ({
  tier,
  frequency,
  loading,
  onClick,
  tierLayout = "full",
  gridLayout = "stacked",
  type,
  comparisonPrice,
  trialEligible,
}: {
  tier: PricingTierType;
  type: PricingPageType;
  frequency: PriceFrequency;
  loading: boolean;
  onClick: (
    priceId: string,
    tier: PricingTierType,
    frequency: PriceFrequency
  ) => void;
  comparisonPrice?: number;
  gridLayout?: "stacked" | "horizontal"; // Layout of the grid overall
  tierLayout?: "full" | "stacked"; // Layout / Positioning of the tier in the grid
  trialEligible?: boolean;
}) => {
  const comparisonPriceMonthly =
    frequency.value === "annually"
      ? (comparisonPrice / 12).toFixed(2)
      : comparisonPrice?.toFixed(2);

  const comparisonPriceYearly =
    frequency.value === "monthly"
      ? (comparisonPrice * 12).toFixed(2)
      : comparisonPrice?.toFixed(2);

  const isTierActiveWithInterval =
    tier.active && tier.activeInterval === frequency.value;

  const getButtonText = useCallback(
    (tier: PricingTierType) => {
      switch (type) {
        case "public":
          return tier.isFree ? "BGB AT lernen" : `Jetzt kostenlos testen`;
        case "conversion":
          return tier.isFree
            ? "BGB AT lernen"
            : getTrialMessage(tier, frequency, trialEligible);
        case "upgrade":
          if (isTierActiveWithInterval) return "Aktuelles Paket verwalten";
          if (tier.active)
            return `Zu ${
              frequency.value === "monthly" ? "monatlicher" : "jährlicher"
            } Zahlung (${tier.price[frequency.value].displayInfo?.priceMonth}/${
              frequency.priceSuffix
            }) wechseln`;

          return `Zu ${tier.subtitle} wechseln`;
        // return `Zu ${tier.subtitle} wechseln`;
      }
    },
    [type, frequency, isTierActiveWithInterval, trialEligible]
  );

  const getButtonType = useCallback(() => {
    if (tier.isFree) {
      return "tertiary";
    }

    if (tier.active) {
      if (isTierActiveWithInterval) {
        return "primary";
      }
      return frequency.value === "annually" ? "upgrade" : "tertiary";
    } else {
      if (tier.mostPopular) {
        return "upgrade";
      }

      if (type !== "public") {
        return "primaryGhost";
      }

      return "upgrade";
    }
  }, [
    tier.active,
    tier.isFree,
    tier.mostPopular,
    isTierActiveWithInterval,
    frequency.value,
  ]);

  return (
    <div
      className={clsx(
        "lg:col-span-1",
        tier.comingSoon ? "opacity-100" : "",
        tier.mostPopular
          ? "bg-gradient-to-br from-white to-white shadow-lg shadow-orange-300 ring-2 ring-orange-500 dark:from-gray-900/50 dark:to-gray-900 dark:shadow-red-600"
          : "bg-white ring-1 ring-gray-900/10 dark:bg-gray-900 dark:ring-white/10",
        "rounded-xl p-8"
      )}
    >
      <div className={clsx("flex flex-col")}>
        <div>
          <div className="flex  items-start justify-between gap-x-4">
            <div className="w-full">
              {!tier.isFree && (
                <div className="mb-5 flex items-center justify-between">
                  <Logo size={tier.mostPopular ? "large" : "medium"} />

                  {tier.mostPopular ||
                  tier.comingSoon ||
                  tier.active ||
                  tier.new ? (
                    <p
                      className={clsx(
                        tier.active
                          ? "bg-lime-50 text-lime-600 ring-lime-600/20 dark:bg-lime-400/10 dark:text-lime-500 dark:ring-lime-400/20"
                          : tier.comingSoon
                          ? "bg-gray-50 text-gray-600 ring-gray-400/20 dark:bg-gray-900 dark:text-gray-300 dark:ring-gray-400/20"
                          : "bg-orange-50 text-orange-600 ring-orange-600/20 dark:bg-orange-400/10 dark:text-orange-500 dark:ring-orange-400/20",
                        "float-right inline-flex select-none items-center justify-center rounded-md px-2.5 py-1 text-xs font-medium ring-1 ring-inset"
                      )}
                    >
                      {tier.active
                        ? "Dein aktuelles Paket"
                        : tier.mostPopular
                        ? comparisonPrice && (
                            <span>
                              Du sparst{" "}
                              <span className="font-extrabold">
                                {(
                                  Number(
                                    frequency.value === "annually"
                                      ? comparisonPriceYearly
                                      : comparisonPriceMonthly
                                  ) -
                                  Number(
                                    tier.price[frequency.value].displayInfo
                                      ?.pricePerIntervalNumber
                                  )
                                ).toFixed(0)}
                                €
                              </span>
                              {frequency.priceSuffix} im Paket
                            </span>
                          )
                        : tier.new
                        ? "Jetzt neu"
                        : "Bald verfügbar"}
                    </p>
                  ) : null}
                </div>
              )}

              <span
                className={clsx(
                  tier.comingSoon
                    ? "text-gray-500 dark:text-gray-300"
                    : "text-orange-600 dark:text-orange-500",
                  "text-sm font-semibold"
                )}
              >
                {tier.subtitle}
              </span>

              <h3
                id={tier.id}
                className={clsx(
                  "text-xl text-gray-800 dark:text-white",
                  "mt-1 font-semibold leading-7"
                )}
              >
                {tier.name}
              </h3>
            </div>
          </div>
          <p className="mt-4 text-sm leading-6 text-gray-600 dark:text-gray-300">
            {tier.description}
          </p>

          {!tier.comingSoon && tier.price[frequency.value] ? (
            <>
              {!tier.active && (
                <div className={clsx("mt-6 flex gap-x-1")}>
                  <div className="flex flex-col">
                    <div className="flex flex-col items-start gap-2">
                      <div>
                        <span className="text-4xl font-bold tracking-tight dark:text-white">
                          {tier.price[frequency.value].displayInfo?.priceMonth}
                        </span>
                        <span className="text-sm font-semibold leading-6 text-gray-500 dark:text-gray-300">
                          {" "}
                          /Monat
                        </span>
                      </div>
                    </div>
                    <div className="mt-2 text-sm text-gray-500 dark:text-gray-300">
                      <div className="font-normal">
                        {tier.price[frequency.value].displayInfo
                          ?.intervalInfo &&
                          `${
                            tier.price[frequency.value].displayInfo
                              ?.intervalInfo
                          }`}
                      </div>

                      <div className="mt-1 w-full">
                        {tier.price[frequency.value].note}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <Button
                className={clsx("mt-6 max-w-xl")}
                loading={loading}
                size="medium"
                disabled={tier.comingSoon}
                onClick={() =>
                  onClick(
                    tier.price?.[frequency.value]?.stripePriceId,
                    tier,
                    frequency
                  )
                }
                animate
                type={getButtonType()}
              >
                {getButtonText(tier)}
              </Button>
            </>
          ) : (
            <div className="mt-4">
              <Button size="small" type="tertiary" disabled Icon={ClockIcon}>
                Bald verfügbar
              </Button>
            </div>
          )}
        </div>
      </div>
      {!tier.comingSoon && (
        <Disclosure defaultOpen={tierLayout === "full"}>
          {({ open }) => (
            <>
              <DisclosureButton className="mt-8 w-full text-left">
                <span className="flex items-center justify-between">
                  <span className="text-sm font-medium text-gray-900 dark:text-gray-100">
                    Was ist enthalten?
                  </span>
                  <ChevronRightIcon
                    className={`${
                      open ? "rotate-90 transform" : ""
                    } h-5 w-5 text-gray-500`}
                  />
                </span>
              </DisclosureButton>

              <Transition
                enter="transition duration-100 ease-out"
                enterFrom="transform scale-95 opacity-0"
                enterTo="transform scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom="transform scale-100 opacity-100"
                leaveTo="transform scale-95 opacity-0"
              >
                <DisclosurePanel>
                  <ul
                    role="list"
                    className={clsx(
                      "mt-4",
                      "space-y-3 text-sm leading-6 text-gray-600 dark:text-gray-200",
                      tier.mostPopular &&
                        gridLayout === "horizontal" &&
                        "xl:columns-2 xl:gap-x-8"
                    )}
                  >
                    {tier.features.map((feature, idx) => (
                      <li key={idx} className={clsx("flex gap-x-3")}>
                        <CheckIcon
                          className="h-6 w-5 flex-none text-orange-500"
                          aria-hidden="true"
                        />
                        {feature}
                      </li>
                    ))}
                  </ul>
                </DisclosurePanel>
              </Transition>
            </>
          )}
        </Disclosure>
      )}
    </div>
  );
};

export default PricingTier;
