export const shouldHideChatWidget = (
  route: string,
  onboardingDismissed: boolean
) => {
  return (
    route.startsWith("/lernen") ||
    route.startsWith("/nachschlagen") ||
    route.startsWith("/ratgeber") ||
    !onboardingDismissed
  );
};
