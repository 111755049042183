import React, { FC } from "react";
import clsx from "clsx";
import { motion } from "framer-motion";

import Spinner from "./Spinner";
import Annotation, { AnnotatiopnPlacement } from "./Annotation";

export interface ButtonProps {
  children: React.ReactNode;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (e: any) => void;
  animate?: boolean;
  htmlType?: "button" | "submit" | "reset";
  Icon?: React.FC<React.SVGProps<SVGSVGElement>>;
  iconPosition?: "left" | "right" | "leftOutside" | "rightOutside";
  size?: "small" | "medium" | "large";
  type?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "white"
    | "danger"
    | "success"
    | "warning"
    | "upgrade"
    | "primaryGhost"
    | "text";
  widthClass?: string;
  [key: string]: any;
  tooltipContent?: React.ReactNode;
  tooltipPlacement?: AnnotatiopnPlacement;
  className?: string;
  width?: "full" | "auto";
}

const Button: FC<ButtonProps> = ({
  children,
  disabled,
  loading,
  onClick,
  widthClass,
  tooltipContent,
  tooltipPlacement = "top",
  htmlType = "button",
  Icon,
  type = "primary",
  iconPosition = "left",
  size = "medium",
  className,
  width = "full",
  animate = true,
  ...props
}) => {
  const spring = {
    type: "spring",
    stiffness: 700,
    damping: 30,
  };

  const button = (
    <motion.button
      {...props}
      whileTap={
        !disabled
          ? {
              scale: 0.98,
            }
          : undefined
      }
      whileHover={
        animate && type !== "text" && !disabled
          ? {
              scale: 1.005,
            }
          : {}
      }
      transition={spring}
      type={htmlType}
      disabled={disabled}
      onClick={onClick}
      className={clsx(
        "disabled:cursor-not-allowed disabled:opacity-50",
        "focus-ring",
        size === "small" && "px-4 py-1.5 text-sm ",
        size === "medium" && "px-4 py-3 text-sm ",
        size === "large" && "px-8 py-4 text-base ",
        !disabled &&
          "shadow-transparent transition-shadow hover:shadow-[0px_4px_8px_rgba(0,0,0,0.05)] dark:hover:shadow-[0px_4px_8px_rgba(0,0,0,0.02)]",
        type === "primary" && [
          "bg-gradient-to-br from-orange-500 to-orange-600",
          "text-white",
          !disabled && !loading && "hover:from-orange-600 hover:to-orange-500",
          "border-orange-500",
          "focus-ring",
        ],
        type === "text" && [
          "bg-transparent",
          "text-slate-900",
          "border-transparent",
          "shadow-none",
          "dark:text-slate-100",
          !disabled &&
            !loading &&
            "hover:bg-slate-200 active:bg-slate-300 dark:hover:bg-slate-800 dark:active:bg-slate-700",
          "focus-ring",
        ],
        type === "success" && [
          "bg-lime-500",
          "dark:bg-lime-600",
          "text-white",
          "!font-semibold",
          "dark:focus:ring-offset-lime-800",
          "focus:ring-lime-500",
          !disabled && !loading && "active:bg-lime-500",
          "border-transparent",
        ],
        type === "upgrade" && [
          "bg-gradient-to-br from-orange-500 to-pink-500 shadow-orange-500/20 dark:from-orange-600 dark:to-pink-600",
          "text-white",
          "!font-semibold",
          !disabled &&
            !loading &&
            "hover:from-pink-500 hover:to-orange-500 hover:dark:from-pink-600 hover:dark:to-orange-600",
          "border-transparent",
          "focus-ring",
        ],
        type === "primaryGhost" && [
          "border-orange-500/80 shadow-orange-500/10",
          "text-slate-900 dark:text-white",
          "!font-semibold",
          !disabled && !loading && "dark:hover:bg-orange-500/10",
          "focus-ring",
        ],
        type === "secondary" && [
          "bg-orange-200 text-orange-600",
          !disabled && !loading && "hover:bg-orange-100 active:bg-orange-200",
          "border-transparent",
          "focus-ring",
        ],
        type === "tertiary" && [
          // "bg-slate-200 dark:bg-slate-700",
          "border border-slate-200 dark:border-slate-700",
          "shadow-none",
          "text-slate-900",
          "dark:text-slate-100",
          "bg-white dark:bg-slate-800",
          !disabled &&
            !loading &&
            "active:bg-slate-200 dark:hover:bg-slate-700/25 dark:active:bg-slate-700",
          "focus-ring",
        ],
        type === "danger" && [
          "bg-red-600",
          "text-white",
          !disabled && !loading && "hover:bg-red-700",
          "border-transparent",
          "focus-ring",
        ],
        type === "warning" && [
          "bg-orange-600",
          "text-white",
          !disabled && !loading && "hover:bg-orange-500",
          "border-transparent",
          "focus-ring",
        ],
        type === "white" && [
          "bg-white",
          "focus:ring-slate-500",
          !disabled && !loading && "hover:bg-gray-50",
          "text-slate-900",
          "border-transparent",
          "focus-ring",
        ],
        widthClass ? widthClass : width === "full" ? "w-full" : "px-4",
        "group relative flex justify-center rounded-full border font-medium focus:outline-none focus:ring-2 focus:ring-offset-2",
        className
      )}
    >
      {Icon &&
        !loading &&
        (iconPosition === "leftOutside" || iconPosition === "rightOutside") && (
          <span
            className={clsx(
              "absolute inset-y-0 flex items-center",
              iconPosition === "rightOutside" ? "right-0 pr-3" : "left-0 pl-3"
            )}
          >
            <Icon className="h-5 w-5" aria-hidden="true" />
          </span>
        )}
      {Icon && !loading && iconPosition === "left" && (
        <Icon
          className={clsx(
            size === "small" && "mr-2 mt-0.5 h-4 w-4",
            size === "medium" && "mr-3 h-5 w-5",
            size === "large" && "mr-5 h-5 w-5"
          )}
        />
      )}{" "}
      {/* render the icon if it exists */}
      {loading ? (
        <Spinner className="h-5 w-5 " />
      ) : (
        <span
          className={clsx(iconPosition === "leftOutside" && "pl-4 sm:pl-0")}
        >
          {children}
        </span>
      )}
      {Icon && iconPosition === "right" && <Icon className={"ml-5 h-5 w-5"} />}{" "}
    </motion.button>
  );

  if (tooltipContent)
    return (
      <Annotation
        content={tooltipContent}
        placement={tooltipPlacement}
        showDelay={100}
        hideDelay={200}
      >
        {button}
      </Annotation>
    );

  return button;
};

export default Button;
