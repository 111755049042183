import { Modal } from "@jurahilfe/shared/components";

import useStore from "@hooks/useStore";

export type DoubleConfirmModalType = "warning" | "info" | "positive";

export const useDoubleConfirm = (
  type?: DoubleConfirmModalType,
  title?: string,
  content?: string
) => {
  const { set } = useStore((state) => ({
    set: state.set,
  }));

  return (onConfirm) => {
    set((state) => {
      state.doubleConfirmModal = {
        open: true,
        title,
        content,
        onConfirm,
        type: type,
      };
    });
  };
};

const DoubleConfirmModal = () => {
  const {
    modalConfig: { open, title, content, onConfirm, type },
    set,
  } = useStore((state) => ({
    modalConfig: state.doubleConfirmModal,
    set: state.set,
  }));

  const handleConfirm = () => {
    onConfirm && onConfirm();
    setModalOpen(false);
  };

  const setModalOpen = (open: boolean) => {
    set((state) => {
      state.doubleConfirmModal = {
        ...state.doubleConfirmModal,
        onConfirm: undefined,
        open,
      };
    });
  };

  return (
    <Modal
      title={title}
      type={
        type === "warning"
          ? "warning"
          : type === "positive"
          ? "success"
          : "info"
      }
      open={open}
      setClose={() => setModalOpen(false)}
      onConfirm={handleConfirm}
      confirmButtonText="Ja"
      cancelButtonText="Nein"
      size="small"
    >
      {content}
    </Modal>
  );
};

export default DoubleConfirmModal;
