import Head from "next/head";

interface SEOTagsProps {
  title: string;
  content?: string;
  description?: string;
  canonicalUrl: string;
  ogType?: "website" | "article";
  ogImage?: string;
  ogImageAlt?: string;
  articlePublishedTime?: string;
  articleModifiedTime?: string;
  articleAuthor?: string;
  articleSection?: string;
  prevPageUrl?: string;
  nextPageUrl?: string;
  structuredData?: Record<string, any>[];
  appendSiteName?: boolean;
  alternateLanguages?: {
    lang: string;
    url: string;
  }[];
  includeHreflang?: boolean;
}

/**
 * SEOTags component for managing all SEO-related meta tags
 *
 * This component handles:
 * - Page title
 * - Meta description
 * - Canonical URL
 * - Open Graph tags (including og:url)
 * - Twitter card tags
 * - Article-specific tags
 * - Pagination links
 * - Structured data
 * - Hreflang tags for internationalization (including self-reference)
 */
export const SEOTags = ({
  title,
  content = "",
  description,
  canonicalUrl,
  ogType = "website",
  ogImage,
  ogImageAlt,
  articlePublishedTime,
  articleModifiedTime,
  articleAuthor,
  articleSection,
  prevPageUrl,
  nextPageUrl,
  structuredData = [],
  appendSiteName = true,
  alternateLanguages = [],
  includeHreflang = true,
}: SEOTagsProps) => {
  // Format title similar to MetaTags component
  const baseTitle = content ? `${title} | ${content}` : title;
  const finalTitle =
    appendSiteName && baseTitle.length <= 45
      ? `${baseTitle} | Jurahilfe.de`
      : baseTitle;

  // Use canonicalUrl as the primary URL
  const finalUrl = canonicalUrl;

  // Default hreflang tags if none provided
  const defaultAlternateLanguages =
    alternateLanguages.length === 0
      ? [
          { lang: "de", url: finalUrl },
          { lang: "x-default", url: finalUrl },
        ]
      : alternateLanguages;

  return (
    <Head>
      {/* Basic Meta Tags */}
      <title>{finalTitle}</title>
      {description && (
        <meta name="description" content={description} key="description" />
      )}
      {finalUrl && <link rel="canonical" href={finalUrl} />}

      {/* Hreflang Tags - including self-reference */}
      {includeHreflang &&
        defaultAlternateLanguages.map(({ lang, url }) => (
          <link
            key={`hreflang-${lang}`}
            rel="alternate"
            hrefLang={lang}
            href={url}
          />
        ))}

      {/* Open Graph Tags */}
      <meta property="og:title" content={finalTitle} key="og:title" />
      {description && (
        <meta
          property="og:description"
          content={description}
          key="og:description"
        />
      )}
      {finalUrl && <meta property="og:url" content={finalUrl} key="og:url" />}
      <meta property="og:type" content={ogType} key="og:type" />
      {ogImage && <meta property="og:image" content={ogImage} key="og:image" />}
      {ogImageAlt && (
        <meta property="og:image:alt" content={ogImageAlt} key="og:image:alt" />
      )}

      {/* Twitter Card Tags */}
      <meta
        name="twitter:card"
        content="summary_large_image"
        key="twitter:card"
      />
      <meta name="twitter:title" content={finalTitle} key="twitter:title" />
      {description && (
        <meta
          name="twitter:description"
          content={description}
          key="twitter:description"
        />
      )}
      {ogImage && (
        <meta name="twitter:image" content={ogImage} key="twitter:image" />
      )}

      {/* Article Specific Tags */}
      {ogType === "article" && articlePublishedTime && (
        <meta
          property="article:published_time"
          content={articlePublishedTime}
          key="article:published_time"
        />
      )}
      {ogType === "article" && articleModifiedTime && (
        <meta
          property="article:modified_time"
          content={articleModifiedTime}
          key="article:modified_time"
        />
      )}
      {ogType === "article" && articleAuthor && (
        <meta
          property="article:author"
          content={articleAuthor}
          key="article:author"
        />
      )}
      {ogType === "article" && articleSection && (
        <meta
          property="article:section"
          content={articleSection}
          key="article:section"
        />
      )}

      {/* Pagination Links */}
      {prevPageUrl && <link rel="prev" href={prevPageUrl} />}
      {nextPageUrl && <link rel="next" href={nextPageUrl} />}

      {/* Structured Data */}
      {structuredData.map((data, index) => (
        <script
          key={`structured-data-${index}`}
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(data) }}
        />
      ))}
    </Head>
  );
};

export default SEOTags;
