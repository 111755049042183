import clsx from "clsx";
import { FC } from "react";

// Simple array of color combinations we can use
const BADGE_COLORS = [
  {
    bg: "bg-blue-50 dark:bg-blue-400/10",
    text: "text-blue-600 dark:text-blue-500",
    ring: "ring-blue-600/20 dark:ring-blue-400/20",
  },
  {
    bg: "bg-purple-50 dark:bg-purple-400/10",
    text: "text-purple-600 dark:text-purple-500",
    ring: "ring-purple-600/20 dark:ring-purple-400/20",
  },
  {
    bg: "bg-green-50 dark:bg-green-400/10",
    text: "text-green-600 dark:text-green-500",
    ring: "ring-green-600/20 dark:ring-green-400/20",
  },
  {
    bg: "bg-yellow-50 dark:bg-yellow-400/10",
    text: "text-yellow-600 dark:text-yellow-500",
    ring: "ring-yellow-600/20 dark:ring-yellow-400/20",
  },
  {
    bg: "bg-pink-50 dark:bg-pink-400/10",
    text: "text-pink-600 dark:text-pink-500",
    ring: "ring-pink-600/20 dark:ring-pink-400/20",
  },
  {
    bg: "bg-indigo-50 dark:bg-indigo-400/10",
    text: "text-indigo-600 dark:text-indigo-500",
    ring: "ring-indigo-600/20 dark:ring-indigo-400/20",
  },
];

export type BadgeType = "neutral" | "highlight" | "upgrade" | "dynamic";
export type BadgeSize = "small" | "medium" | "large";

export interface BadgeProps {
  children: React.ReactNode;
  type?: BadgeType;
  size?: BadgeSize;
  className?: string;
}

// Function to consistently generate a number from a string
const hashString = (str: string): number => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) - hash + str.charCodeAt(i);
    hash = hash & hash;
  }
  return Math.abs(hash);
};

const Badge: FC<BadgeProps> = ({
  children,
  type = "neutral",
  size = "medium",
  className,
  ...props
}) => {
  // Get color based on content if type is dynamic
  const colorIndex =
    type === "dynamic" && typeof children === "string"
      ? hashString(children) % BADGE_COLORS.length
      : 0;
  const dynamicColor = BADGE_COLORS[colorIndex];

  return (
    <span
      className={clsx(
        "whitespace-nowrap font-medium",
        "inline-flex select-none items-center justify-center rounded-md ring-1 ring-inset",
        size === "small" && "px-1.5 py-0.5 text-xs",
        size === "medium" && "px-2 py-1 text-xs",
        size === "large" && "px-3 py-1.5 text-sm",
        type === "neutral" &&
          "bg-slate-50 text-slate-600 ring-transparent  dark:bg-slate-700/50 dark:text-slate-300",
        type === "highlight" &&
          "bg-orange-50 text-orange-600 ring-orange-600/20 dark:bg-orange-400/10 dark:text-orange-500 dark:ring-orange-400/20",
        type === "upgrade" && [
          "bg-gradient-to-br from-orange-600/80 to-pink-600/80 shadow-orange-500/50 ring-orange-600/40 dark:from-orange-600/40 dark:to-pink-600/40",
          "transition",
          "text-white",
          "!font-semibold",
        ],
        type === "dynamic" && [
          dynamicColor.bg,
          dynamicColor.text,
          dynamicColor.ring,
        ],
        className
      )}
      {...props}
    >
      {children}
    </span>
  );
};

export default Badge;
