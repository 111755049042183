import React from "react";

import { Modal } from "@jurahilfe/shared/components";
import useStore from "@hooks/useStore";
import PlanSelector from "./PlanSelector";

const UpgradeModal = () => {
  const { showUpgradeModal, set } = useStore((state) => ({
    showUpgradeModal: state.showUpgradeModal,
    set: state.set,
  }));

  const closeModal = () => {
    set(() => ({
      showUpgradeModal: false,
    }));
  };

  return (
    <Modal
      type="upgrade"
      size="xlarge"
      open={showUpgradeModal}
      setClose={closeModal}
      cancelButtonText="Schließen"
      title={
        <span>
          Jurahilfe <span className="text-premium">Pro</span> freischalten
        </span>
      }
      confirmButtonText={false}
    >
      <PlanSelector />
    </Modal>
  );
};

export default UpgradeModal;
