import { create } from "zustand";
import { produce } from "immer";
import { devtools } from "zustand/middleware";

import type { SharedState, SharedActions } from "../types";

export const useSharedStore = create<SharedState & SharedActions>()(
  devtools(
    (set) => ({
      cookieChoiceUpdated: 0,
      cookieDetails: [
        {
          category: "Notwendig",
          descriptionText:
            "Notwendige Cookies sind für die grundlegende Funktionalität der Website erforderlich. Diese Cookies sind immer aktiviert.",
          cookies: [
            {
              cookieName: "cookieChoiceDone",
              cookieDuration: "1 Jahr",
              cookieService: "1st Party Cookie",
              cookieDescription:
                "Bestimmt ob der Nutzer die Cookie Auswahl bereits getätigt hat.",
            },
            {
              cookieName: "acceptStatistic",
              cookieDuration: "1 Jahr",
              cookieService: "1st Party Cookie",
              cookieDescription:
                "Bestimmt ob der Nutzer der Verwendung von Statistik Cookies zugestimmt hat.",
            },
            {
              cookieName: "acceptMarketing",
              cookieDuration: "1 year",
              cookieService: "1st Party Cookie",
              cookieDescription:
                "Bestimmt ob der Nutzer der Verwendung von Marketing Cookies zugestimmt hat.",
            },
            {
              cookieName: "acceptExternalMedia",
              cookieDuration: "1 year",
              cookieService: "1st Party Cookie",
              cookieDescription:
                "Bestimmt ob der Nutzer der Verwendung von externen Medien Cookies zugestimmt hat.",
            },
            {
              cookieName: "_GRECAPTCHA",
              cookieDuration: "90 Tage",
              cookieService: "Google Recaptcha",
              cookieDescription:
                "Notwendig um die Google Recaptcha zu verwenden.",
            },
          ],
        },
        {
          category: "Statistik",
          descriptionText:
            "Statistik-Cookies helfen uns zu verstehen, wie unsere Besucher unsere Website nutzen. Anhand dieser Informationen können wir die Leistung unserer Website verbessern und die Benutzererfahrung für Sie verbessern.",
          cookies: [
            {
              cookieName: "_hp2_id.APP_ID",
              cookieDuration: "13 Monate",
              cookieService: "Heap.io",
              cookieDescription:
                "User-Cookie (speichert user_id, und andere ids)",
            },
            {
              cookieName: "_hp2_ses_props.APP_ID",
              cookieDuration: "30 Minuten",
              cookieService: "Heap.io",
              cookieDescription:
                "Session-Properties-Cookie (speichert Timestamp und Cookie-Domain/Pfad)",
            },
            {
              cookieName: "_hp2_props.APP_ID",
              cookieDuration: "13 Monate",
              cookieService: "Heap.io",
              cookieDescription:
                "Event-Properties-Cookie (speichert Properties der addEventProperties API)",
            },
            {
              cookieName: "_hp2_hld.",
              cookieDuration: "Sollte nicht bestehen bleiben",
              cookieService: "Heap.io",
              cookieDescription:
                "Wird verwendet um zu bestimmen, auf welchem Domain ein Cookie gesetzt werden kann (da Public Suffix Domains das Setzen von Cookies auf der obersten Ebene blockieren)",
            },
            // {
            //   cookieName: "_ga",
            //   cookieDuration: "2 years",
            //   cookieService: "Google Analytics",
            //   cookieDescription: "Used to distinguish users.",
            // },
            // {
            //   cookieName: "_gid",
            //   cookieDuration: "24 hours",
            //   cookieService: "Google Analytics",
            //   cookieDescription: "Used to distinguish users.",
            // },
            // {
            //   cookieName: "_ga_<container-id>",
            //   cookieDuration: "2 years",
            //   cookieService: "Google Analytics",
            //   cookieDescription: "Used to persist session state.",
            // },
            // {
            //   cookieName: "_gac_gb_<container-id>",
            //   cookieDuration: "90 days",
            //   cookieService: "Google Analytics",
            //   cookieDescription: "Contains campaign related information.",
            // },
            {
              cookieName: "APISID, HSID, SAPISID, SID, SSID",
              cookieDuration: "2 years",
              cookieService: "Google Recaptcha",
              cookieDescription:
                "These Google security cookies help to authenticate the user, prevent the fraudulent use of login information and protect user data from unauthorized access.",
            },
          ],
        },
        {
          category: "Marketing",
          descriptionText:
            "Marketing-Cookies werden verwendet, um Besuchern auf Websites zu folgen. Die Absicht ist, Anzeigen zu zeigen, die relevant und ansprechend für den einzelnen Benutzer sind und daher wertvoller für Publisher und werbetreibende Drittparteien sind.",
          cookies: [
            {
              cookieName: "_fbp",
              cookieDuration: "3 Monate",
              cookieService: "Meta Pixel",
              cookieDescription:
                "Wird von Meta verwendet, um eine Reihe von Werbeprodukten anzubieten, zum Beispiel Echtzeitgebote von Werbetreibenden.",
            },
            {
              cookieName: "fr",
              cookieDuration: "3 Monate",
              cookieService: "Meta Pixel",
              cookieDescription:
                "Wird von Meta für Werbe- und Tracking-Zwecke verwendet.",
            },
          ],
        },
      ],
      analyticScriptIsLoaded: false,
      marketingScriptIsLoaded: false,
      sidebarCollapsed: false,
      sidebarOpen: false,

      // Actions
      set: (fn) => set(produce(fn)),
      updateCookieChoice: () =>
        set((state) => ({
          cookieChoiceUpdated: state.cookieChoiceUpdated + 1,
        })),
      setAnalyticsLoaded: (loaded) => set({ analyticScriptIsLoaded: loaded }),
      setMarketingLoaded: (loaded) => set({ marketingScriptIsLoaded: loaded }),
    }),
    { name: "SharedStore" }
  )
);
