import React from "react";
import clsx from "clsx";
import { Tab, TabGroup, TabList } from "@headlessui/react";
import { motion } from "framer-motion";
// Reusable tab item props
export interface TabItem {
  icon: React.ReactElement;
  text: string;
  disabled?: boolean;
}

// TabSelector component props
export interface TabSelectorProps {
  items: TabItem[];
  layoutId: string;
  selectedIndex: number;
  className?: string;
  onChange: (index: number) => void;
}

// Simplified TabSelector component using HeadlessUI directly
const TabSelector: React.FC<TabSelectorProps> = ({
  items,
  layoutId,
  selectedIndex,
  onChange,
  className,
}) => {
  return (
    <TabGroup
      selectedIndex={selectedIndex}
      onChange={onChange}
      className="rounded-full bg-white px-2 dark:bg-transparent lg:pl-2 lg:pr-0"
    >
      <TabList
        className={clsx(
          "flex rounded-full bg-slate-50 p-1 dark:bg-slate-800 dark:shadow-lg",
          className
        )}
      >
        {items.map((item, index) => (
          <Tab
            key={index}
            disabled={item.disabled}
            className={({ selected }) =>
              clsx(
                "relative",
                "text-xs",
                "flex items-center gap-1.5 rounded-full px-3.5 py-1.5 text-sm font-medium outline-none transition-colors",
                selected && !item.disabled
                  ? "text-orange-500"
                  : item.disabled
                  ? "cursor-not-allowed text-slate-400 dark:text-slate-500"
                  : "text-slate-500  hover:text-slate-700 dark:text-slate-400 dark:hover:text-slate-300"
              )
            }
          >
            <div className="relative z-10 flex items-center gap-1.5">
              {React.cloneElement(item.icon, {
                className: "h-3.5 w-3.5 flex-shrink-0",
              })}
              <span>{item.text}</span>
            </div>
            {/* If the tab is selected, let's add a background. */}
            {selectedIndex === index && (
              <motion.div
                className="absolute inset-0 rounded-full bg-white dark:bg-slate-700/50"
                layoutId={`${layoutId}-tab-background`}
              />
            )}
          </Tab>
        ))}
      </TabList>
    </TabGroup>
  );
};

export default TabSelector;
