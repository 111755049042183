import Image from "next/image";
import clsx from "clsx";

import LogoTextWhite from "../assets/logo_fullsize_white.svg";
import LogoTextBlack from "../assets/logo_fullsize_black.svg";

const LogoText = ({
  size = "small",
  className,
}: {
  size?: "small" | "medium" | "large";
  className?: string;
}) => {
  return (
    <>
      <Image
        className={clsx(
          "hidden dark:block",
          size === "small" && "h-10 max-w-min",
          size === "medium" && "h-12 max-w-min",
          size === "large" && "h-16 max-w-min",
          className
        )}
        src={LogoTextWhite}
        alt="Logo"
      />
      <Image
        className={clsx(
          "dark:hidden",
          size === "small" && "h-10 max-w-min",
          size === "medium" && "h-12 max-w-min",
          size === "large" && "h-16 max-w-min",
          className
        )}
        src={LogoTextBlack}
        alt="Logo"
      />
    </>
  );
};

export default LogoText;
