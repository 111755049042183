import { motion, AnimatePresence } from "framer-motion";
import { usePageTransition } from "../hooks/usePageTransition";

export function PageTransitionLoader() {
  const { isLoading } = usePageTransition();

  return (
    <AnimatePresence>
      {isLoading && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
          className="fixed inset-0 z-30 flex items-center justify-center bg-white/80 backdrop-blur-sm dark:bg-gray-900/80"
        >
          <div className="flex flex-col items-center">
            <div className="mb-4 h-12 w-12 animate-spin rounded-full border-4 border-orange-500 border-t-transparent">
              <span className="sr-only">Wird geladen...</span>
            </div>
            <p className="text-lg font-medium text-gray-700 dark:text-gray-300">
              Seite wird geladen...
            </p>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
