export const initMetaPixel = (pixelId: string, onLoad?: () => void) => {
  const script = document.createElement("script");
  script.id = "meta-pixel";
  script.innerHTML = `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '${pixelId}');
      fbq('track', 'PageView');
    `;
  script.async = true;

  const noscript = document.createElement("noscript");
  const img = document.createElement("img");
  img.height = 1;
  img.width = 1;
  img.style.display = "none";
  img.src = `https://www.facebook.com/tr?id=${pixelId}&ev=PageView&noscript=1`;
  noscript.appendChild(img);

  document.head.appendChild(script);
  document.body.appendChild(noscript);

  if (onLoad) onLoad();
};

export const removeMetaPixel = () => {
  // Remove Meta Pixel script
  const script = document.getElementById("meta-pixel");
  if (script) script.remove();

  // Remove noscript fallback
  const noscript = document.querySelector(
    'noscript > img[src*="facebook.com/tr"]'
  );
  if (noscript?.parentElement) noscript.parentElement.remove();

  // Remove fbq function
  (window as any).fbq = undefined;
  (window as any)._fbq = undefined;
};
