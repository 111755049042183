import React, { useRef } from "react";
import { useRouter } from "next/router";
import clsx from "clsx";
import { useMedia, useClickAway } from "react-use";
import { DisclosureButton, DisclosurePanel, useClose } from "@headlessui/react";

import { HeaderNavigationItem, UserMenuItem, NavbarType } from "../../types";
import ThemeSwitcher from "./ThemeSwitcher";
import Link from "next/link";

const MobileMenu = ({
  type,
  navigationItems,
  userMenuConfig,
  extra,
}: {
  type: NavbarType;
  navigationItems: HeaderNavigationItem[];
  userMenuConfig?: {
    items: UserMenuItem[];
    photoURL?: string;
    email?: string;
  };
  extra?: React.ReactNode;
}) => {
  const isLg = useMedia("(min-width: 1024px)", true);
  const router = useRouter();

  const mobileMenuRef = useRef(null);

  const close = useClose();

  useClickAway(mobileMenuRef, (event: MouseEvent) => {
    const target = event.target as HTMLElement;
    // Don't close if clicking the toggle button
    if (target.closest('[aria-label="Hauptmenu öffnen"]')) {
      return;
    }
    close && close();
  });

  return (
    <DisclosurePanel
      ref={mobileMenuRef}
      transition
      className={clsx(
        isLg && "hidden",
        "origin-top transition duration-200 ease-out data-[closed]:-translate-y-6 data-[closed]:opacity-0"
      )}
    >
      <>
        <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
          {extra && <div className="mb-4 px-3">{extra}</div>}
          <div className="mb-4 flex items-end justify-between">
            <h2 className="px-3 text-sm font-semibold text-gray-500 dark:text-gray-400">
              Navigation
            </h2>
            <ThemeSwitcher type="mobile" />
          </div>
          {navigationItems.map((item) => (
            <MobileMenuLink
              key={item.name}
              name={item.name}
              isActive={router.pathname === item.href}
              href={item.href}
              icon={item.icon}
            />
          ))}
        </div>
        <div className="border-b border-t border-slate-300 bg-slate-200/50 px-2 py-1 dark:border-gray-700 dark:bg-gray-700/50 sm:px-3">
          {type === "app" && userMenuConfig && (
            <div>
              <div className="space-y-1">
                {userMenuConfig.items.map((item, i) => {
                  if (item.name === "divider") {
                    return (
                      <div
                        key={`divider-${i}`}
                        className="h-px bg-slate-200 dark:bg-gray-600"
                      />
                    );
                  }

                  return (
                    <MobileMenuLink
                      key={item.name}
                      highlighted={item.highlighted}
                      name={item.name}
                      icon={item.icon}
                      href={item.href || ""}
                      isActive={false}
                      onClick={item.onClick}
                    />
                  );
                })}
              </div>
            </div>
          )}
          {type === "homepage" && (
            <div className="space-y-1 px-2">
              <MobileMenuLink
                empathise
                name="Registrieren"
                isActive={router.pathname === "/registrieren"}
                href={`${process.env.NEXT_PUBLIC_APP_URL}/registrieren`}
              />
              <MobileMenuLink
                name="Einloggen"
                isActive={router.pathname === "/login"}
                href={`${process.env.NEXT_PUBLIC_APP_URL}/login`}
              />
            </div>
          )}
        </div>
      </>
    </DisclosurePanel>
  );
};

const MobileMenuLink = ({
  name,
  href,
  isActive,
  icon: Icon,
  empathise,
  highlighted,
  size = "md",
  onClick,
}: {
  name: string;
  href?: string;
  isActive: boolean;
  icon?: React.ElementType;
  empathise?: boolean;
  highlighted?: boolean;
  size?: "sm" | "md";
  onClick?: () => void;
}) => {
  return (
    <DisclosureButton
      as={onClick ? "button" : Link}
      {...(onClick ? { onClick } : { href: href || "" })}
      className={clsx(
        "btn-text-default",
        "flex items-center rounded-md px-3 py-2 font-medium",
        empathise ? "border border-orange-500 " : "",
        highlighted ? "!text-primary-default" : "",
        size === "sm" ? "text-sm" : "text-base",
        "hover:bg-gray-100 dark:hover:bg-gray-700/70 dark:hover:text-white"
      )}
      {...(!onClick && { "aria-current": isActive ? "page" : undefined })}
    >
      {Icon && <Icon className="mr-2 h-4 w-4" />}
      {name}
    </DisclosureButton>
  );
};

export default MobileMenu;
