import {
  BookOpenIcon,
  ScaleIcon,
  BuildingLibraryIcon,
  DocumentTextIcon,
  AcademicCapIcon,
} from "@heroicons/react/24/outline";

export const contentInfo: {
  name: string;
  subtitle?: string;
  description: string;
  shortDescription?: string;
  type?: "bonus" | "main";
  icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
  content?: string;
  chapters?: { title: string; available: boolean }[];
  stats?: { paths: string; cards: string; questions?: string };
  preview?: boolean;
}[] = [
  {
    name: "Zivilrecht",
    description:
      "Zivilrechtliches Allgemeinwissen - Allgemeiner Teil des BGB -Schuldrecht Allgemeiner Teil - Vertragliche Schuldverhältnisse - Gesetzliche Schuldverhältnisse - Allgemeines Sachenrecht - Mobiliarsachenrecht - Immobiliarsachenrecht",
    shortDescription: "Mit dem Hauptkurs gut gerüstet für die Prüfung",
    icon: ScaleIcon,
    content:
      "35+ Lernpfade mit 1000+ Karten und Wiederholungsfragen sowie 450+ Multiple-Choice Fragen",
    chapters: [
      { title: "Zivilrechtliches Allgemeinwissen", available: true },
      { title: "Allgemeiner Teil des BGB", available: true },
      { title: "Schuldrecht Allgemeiner Teil", available: true },
      { title: "Vertragliche Schuldverhältnisse", available: true },
      { title: "Gesetzliche Schuldverhältnisse", available: true },
      { title: "Allgemeines Sachenrecht", available: true },
      { title: "Mobiliarsachenrecht", available: true },
      { title: "Immobiliarsachenrecht", available: true },
      { title: "Zilvilrechtliche Nebengebiete", available: false },
      // { title: "Familienrecht", available: false },
      // { title: "Erbrecht", available: false },
      // { title: "Handelsrecht", available: false },
      // { title: "Gesellschaftsrecht", available: false },
      // { title: "Zivilprozessrecht", available: false },
      // { title: "Zwangsvollstreckungsrecht", available: false },
      // { title: "Arbeitsrecht", available: false },
      // { title: "Internationales Privatrecht", available: false },
    ],
    stats: {
      paths: "35+",
      cards: "1000+",
      questions: "450+",
    },
    preview: false,
  },
  {
    name: "Strafrecht",
    description:
      "Allgemeiner Teil des StGB - Nichtvermögensdelikte - Vermögensdelikte - Strafprozessrecht",
    shortDescription: "Gesamtes Strafrecht schnell und einfach erfassen",
    icon: BuildingLibraryIcon,
    content:
      "25 Lernpfade mit 450+ Karten und Wiederholungsfragen, Multiple-Choice Fragen sind bald verfügbar",
    chapters: [
      { title: "Strafrechtliches Allgemeinwissen", available: true },
      { title: "Allgemeiner Teil des StGB", available: true },
      { title: "Nichtvermögensdelikte", available: true },
      { title: "Vermögensdelikte", available: true },
      { title: "Strafprozessrecht", available: true },
    ],
    stats: {
      paths: "25+",
      cards: "550+",
      questions: "340+",
    },
    preview: false,
  },
  {
    name: "Öffentliches Recht",
    description: "Bald verfügbar",
    shortDescription: "Strukturen verstehen und mit Argumenten punkten",
    icon: BookOpenIcon,
    content: "Bald verfügbar",
    chapters: [
      { title: "Öffentlich-rechtliches Allgemeinwissen", available: true },
      { title: "Staatsorganisationsrecht", available: true },
      { title: "Grundrechte", available: false },
      { title: "Verfassungsprozessrecht", available: false },
      { title: "Allgemeines Verwaltungsrecht", available: false },
      { title: "Verwaltungsprozessrecht", available: false },
      { title: "Besonderes Verwaltungsrecht", available: false },
      { title: "Europarecht", available: false },
    ],
    stats: {
      paths: "5+",
      cards: "50+",
    },
    preview: true,
  },
  {
    name: `Ratgeber "How-to-Jura"`,
    type: "bonus",
    description:
      "Aufbau juristischer Arbeiten - Gutachtenstil-Trainer - Zitierratgeber",
    shortDescription:
      "Juristische Arbeiten elegant und stilsicher schreiben lernen",
    icon: DocumentTextIcon,
    content:
      "Bonusinhalte mit Wissen zum Aufbau juristischer Arbeiten, Gutachtenstil und Zitieren",
    chapters: [
      { title: "Aufbau juristischer Arbeiten", available: true },
      { title: "Gutachtenstil-Trainer", available: true },
      { title: "Zitierratgeber", available: true },
    ],
  },
  {
    name: "Juristisches Allgemeinwissen",
    type: "bonus",
    description:
      "Arbeit im Gutachten - Rechts- und Gerichtswesen - Rechtsgeschichte",
    shortDescription:
      "Was du eigentlich wissen musst, aber niemand sonst dir systematisch erklärt",
    icon: AcademicCapIcon,
    content:
      "9 Lernpfade zur Arbeit im Gutachten, zum Rechts- und Gerichtswesen und Rechtsgeschichte",
    chapters: [
      { title: "Arbeit im Gutachten", available: true },
      { title: "Rechts- und Gerichtswesen", available: true },
      { title: "Rechtsgeschichte", available: true },
      { title: "Latein für Angeber", available: true },
    ],
  },
];
