import clsx from "clsx";
import { useRouter } from "next/router";
import { useCallback, useEffect, useRef } from "react";
import Image from "next/image";
import { useSanitized } from "@hooks/useSanizied";
import useStore from "@hooks/useStore";

const ContentDisplay = ({
  htmlString,
  className,
  hideLinkTags,
  highlightTag,
  demoMode,
}: {
  htmlString: string;
  className?: string;
  hideLinkTags?: string[];
  highlightTag?: string;
  demoMode?: boolean;
}) => {
  const sanitizedContent = useSanitized(htmlString);
  const router = useRouter();

  const { openLinkPeek, openModal } = useStore((state) => ({
    openLinkPeek: state.openLinkPeek,
    openModal: state.openModal,
  }));

  const contentRef = useRef(null);

  const handleLinkClick = useCallback(
    (tag) => {
      if (demoMode) {
        openModal({
          title: "Interaktive Links",
          content: (
            <div>
              <div className="mb-5 text-slate-700 dark:text-slate-300">
                In der Jurahilfe.de Lernplattform findest du interaktiv weitere
                Inhalte zum Begriff &quot;{tag}&quot; verlinkt.
              </div>
              <Image
                className="rounded-md"
                alt="Interkative Links"
                src="/linkDemo.gif"
                width={500}
                height={300}
              />
            </div>
          ),
          type: "success",
          cancelButtonText: "",
          confirmButtonText: "Jetzt kostenlos starten",
          onConfirm: () => {
            router.push(`${process.env.NEXT_PUBLIC_HOMEPAGE_URL}`);
          },
        });
      } else {
        openLinkPeek(tag);
      }
    },
    [demoMode, openLinkPeek, openModal]
  );

  useEffect(() => {
    const content = contentRef.current;

    if (!content) return;

    const links = content.querySelectorAll(
      ".prose-answer-text span.matched-tag"
    );

    const onClick = (event) => {
      event.preventDefault();
      event.stopPropagation();
      const tag = event.target.getAttribute("data-matched-tag");

      handleLinkClick(tag);
    };

    links.forEach((link) => {
      link.addEventListener("click", onClick);

      const linkData = link.getAttribute("data-matched-tag");

      // Apply special highlight for the tag that opened the LinkPeek
      if (highlightTag && linkData === highlightTag.replace(/ /g, "_")) {
        link.classList.add("highlighted-tag");
      }

      // Check if link should be hidden, remove the matched-tag class
      if (
        hideLinkTags?.map((link) => link.replace(/ /g, "_")).includes(linkData)
      ) {
        link.classList.remove("matched-tag");
      }

      // Remove underline
      // Move up the dom tree for max 3 leves and check if any parent is <u> tag
      // let parent = link.parentElement;
      // let i = 0;
      // while (i < 3) {
      //   if (parent.tagName === "U") {
      //     parent.style.textDecoration = "none";
      //     break;
      //   }
      //   parent = parent.parentElement;
      //   i++;
      // }
    });

    return () => {
      links.forEach((link) => {
        link.removeEventListener("click", onClick);
      });
    };
  }, [sanitizedContent, hideLinkTags, highlightTag, demoMode, handleLinkClick]);

  return (
    <>
      <div
        ref={contentRef}
        className={clsx("prose-answer-text whitespace-pre-line", className)}
        dangerouslySetInnerHTML={{ __html: sanitizedContent.__html }}
      />
    </>
  );
};

export default ContentDisplay;
