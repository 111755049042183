import React from "react";
import { Bars3Icon, XMarkIcon, FolderIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { useRouter } from "next/router";
import Link from "next/link";
import { useMedia } from "react-use";
import { Disclosure, DisclosureButton } from "@headlessui/react";

import { HeaderNavigationItem, UserMenuItem, NavbarType } from "../../types";

import Logo from "../Logo";
import ThemeSwitcher from "./ThemeSwitcher";
import MenuLink from "../MenuLink";
import MobileMenuPanel from "./MobileMenuPanel";
import Button from "../Button";
import UserMenu from "./UserMenu";

import { useSharedStore } from "../../hooks";
import { useTrack } from "../../hooks";

export interface NavbarProps {
  navigationItems: HeaderNavigationItem[];
  type: NavbarType;
  hideShadow?: boolean;
  extra?: React.ReactNode;
  userMenuConfig?: {
    photoURL?: string;
    email?: string;
    items: UserMenuItem[];
    extra?: React.ReactNode;
  };
  searchButton?: React.ReactNode;
  mobileExtra?: React.ReactNode;
}

const Navbar: React.FC<NavbarProps> = ({
  type,
  navigationItems,
  userMenuConfig,
  extra,
  searchButton,
  hideShadow,
  mobileExtra,
}) => {
  const isLg = useMedia("(min-width: 1024px)", true);
  const router = useRouter();
  const track = useTrack();

  const { set, sidebarCollapsed, sidebarOpen } = useSharedStore((state) => ({
    set: state.set,
    sidebarCollapsed: state.sidebarCollapsed,
    sidebarOpen: state.sidebarOpen,
  }));

  return (
    <Disclosure
      as="nav"
      className={clsx(
        "navbar",
        "sticky top-0 z-40 border-slate-200 backdrop-blur-sm",
        hideShadow // Hide shadow if small screen on /study page
          ? "border-none shadow-none sm:border-b sm:shadow-sm"
          : "border-b shadow-sm",
        type === "default"
          ? "dark:border-transparent dark:bg-gray-800/40"
          : type === "app"
          ? "border-transparent bg-white dark:bg-slate-800"
          : "bg-white/40 dark:border-gray-700 dark:bg-gray-800/40"
      )}
    >
      {({ open }) => (
        <>
          <div className="mx-auto max-w-full px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                {/* Sidebar Icon */}
                {(router.pathname.startsWith("/lernen") ||
                  // Check if pathName starts with "/nachschlagen"
                  router.pathname.startsWith("/nachschlagen") ||
                  router.pathname.startsWith("/ratgeber") ||
                  router.pathname == "/wiederholen") && (
                  <div
                    className={clsx(
                      isLg && !sidebarCollapsed ? "hidden" : "block",
                      "transform transition",
                      !sidebarOpen
                        ? "translate-x-0 opacity-100"
                        : "-translate-x-20 opacity-0"
                    )}
                  >
                    <button
                      type="button"
                      className={clsx(
                        "-ml-2 mr-3 inline-flex h-10 w-10 items-center justify-center rounded-md bg-opacity-80 md:-ml-3 lg:-ml-5",
                        "btn-text-default"
                      )}
                      onClick={() => {
                        set((state) => {
                          state.sidebarOpen = !sidebarOpen;
                        });
                      }}
                    >
                      <span className="sr-only">Sidebar öffnen</span>
                      <FolderIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                  </div>
                )}
                {/* Logo */}
                <div className="flex-shrink-0">
                  <Link
                    href={
                      type === "app"
                        ? "/start"
                        : `${process.env.NEXT_PUBLIC_HOMEPAGE_URL}`
                    }
                    className="focus-ring flex items-center rounded-md"
                    onClick={() => track("click-logo")}
                  >
                    <Logo />
                  </Link>
                </div>
              </div>
              {/* Navigation Items: Large Screen */}
              <div
                className={clsx(
                  isLg ? "flex flex-grow justify-between" : "hidden"
                )}
              >
                <div
                  className={clsx(
                    type === "homepage" ? "" : "",
                    "ml-8 flex items-center space-x-3"
                  )}
                >
                  {navigationItems?.map((item) => (
                    <MenuLink
                      key={item.name}
                      href={item.href}
                      newTab={item.newTab}
                      isActive={router.pathname.startsWith(item.href)}
                      icon={item.icon}
                    >
                      {item.name}
                    </MenuLink>
                  ))}
                </div>
                <div className="flex items-center space-x-3">
                  <div
                    className={clsx(
                      isLg ? "block" : "hidden",
                      "flex items-center"
                    )}
                  >
                    {type === "app" && (
                      <div className="mr-3 flex items-center gap-2 lg:mr-4">
                        {extra && <div>{extra}</div>}
                        {searchButton && <div>{searchButton}</div>}
                      </div>
                    )}
                    <ThemeSwitcher />
                    {/* {extra && <div className="flex gap-2">{extra}</div>} */}
                    {type === "homepage" && (
                      <div className="flex gap-2">
                        {!router.pathname.includes("/login") && (
                          <MenuLink
                            href={`${process.env.NEXT_PUBLIC_APP_URL}/login`}
                          >
                            Einloggen
                          </MenuLink>
                        )}
                        {!router.pathname.includes("/registrieren") && (
                          <MenuLink
                            type="primary"
                            href={`${process.env.NEXT_PUBLIC_APP_URL}/registrieren`}
                          >
                            Kostenlos registrieren
                          </MenuLink>
                        )}
                      </div>
                    )}
                    {type === "app" && userMenuConfig && (
                      <UserMenu userMenuConfig={userMenuConfig} />
                    )}
                  </div>
                </div>
              </div>
              {/* Navigation Items: Small Screen */}
              <div
                className={clsx("-mr-2", isLg ? "hidden" : "flex items-center")}
              >
                {type === "app" && (
                  <div className="mr-3 flex items-center gap-2">
                    {extra && <div>{extra}</div>}
                    {searchButton && <div>{searchButton}</div>}
                  </div>
                )}
                {/* {user && router.pathname === "/" && (
                    <div className="mr-4">
                      <Button
                        size="small"
                        className="whitespace-nowrap"
                        onClick={() => router.push("/start")}
                      >
                        App starten
                      </Button>
                    </div>
                  )} */}
                {type === "homepage" && (
                  <div className="mr-4">
                    {router.pathname.includes("/registrieren") ? (
                      <Button
                        size="small"
                        type="text"
                        className="whitespace-nowrap"
                        onClick={() =>
                          router.push(
                            `${process.env.NEXT_PUBLIC_APP_URL}/login`
                          )
                        }
                      >
                        Einloggen
                      </Button>
                    ) : (
                      <Button
                        size="small"
                        className="whitespace-nowrap"
                        onClick={() =>
                          router.push(
                            `${process.env.NEXT_PUBLIC_APP_URL}/registrieren`
                          )
                        }
                      >
                        Kostenlos registrieren
                      </Button>
                    )}
                  </div>
                )}

                <DisclosureButton
                  className={clsx(
                    "inline-flex items-center justify-center rounded-lg p-2",
                    "bg-white/10 backdrop-blur-sm dark:bg-slate-800/50",
                    "hover:bg-slate-100 dark:hover:bg-slate-700/50",
                    "transition-all duration-200 ease-in-out",
                    "shadow-sm ring-1 ring-slate-200/20 dark:ring-slate-700/50",
                    "focus:outline-none focus:ring-2 focus:ring-orange-500 dark:focus:ring-orange-500"
                  )}
                  aria-label="Hauptmenu öffnen"
                  // Don't do anything when open, because we handle the click away manually
                >
                  <span className="sr-only">Hauptmenu öffnen</span>
                  {open ? (
                    <XMarkIcon
                      className="block h-5 w-5 text-slate-700 dark:text-slate-200"
                      aria-hidden="true"
                    />
                  ) : (
                    <Bars3Icon
                      className="block h-5 w-5 text-slate-700 dark:text-slate-200"
                      aria-hidden="true"
                    />
                  )}
                </DisclosureButton>
              </div>
            </div>
          </div>
          <MobileMenuPanel
            type={type}
            navigationItems={navigationItems}
            userMenuConfig={userMenuConfig}
            extra={mobileExtra}
          />
        </>
      )}
    </Disclosure>
  );
};

export default Navbar;
