import { CookieName } from "../types";

export const COOKIE_DOMAIN =
  process.env.NODE_ENV === "production" ? ".jurahilfe.de" : undefined;

export const COOKIE_OPTIONS = {
  domain: COOKIE_DOMAIN,
  maxAge: 31536000, // 1 year in seconds
  secure: process.env.NODE_ENV === "production",
  sameSite: "lax" as const,
  path: "/",
};

export const COOKIE_NAMES: Record<string, CookieName> = {
  CHOICE_DONE: "cookieChoiceDone",
  STATISTIC: "acceptStatistic",
  MARKETING: "acceptMarketing",
  EXTERNAL_MEDIA: "acceptExternalMedia",
} as const;
