import React from "react";
import clsx from "clsx";
import { useRouter } from "next/router";
import { useEffect } from "react";

import { useMainHeight } from "../hooks/useMainHeight";

function MainLayout({
  children,
  type = "normal",
  addRelativeClass,
}: {
  children: React.ReactNode;
  type?: "filling" | "normal" | "fillingScroll" | "fixedWitdhScroll";
  addRelativeClass?: boolean;
}) {
  const mainHeight = useMainHeight();

  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = () => {
      // Find the main scrollable container
      const mainContainer = document.querySelector(
        '[class*="overflow-y-auto"]'
      );
      if (mainContainer) {
        mainContainer.scrollTop = 0;
      } else {
        // Fallback to window scroll
        window.scrollTo(0, 0);
      }
    };

    router.events.on("routeChangeComplete", handleRouteChange);

    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [router]);

  return (
    // Set max width and paddings for all content on the pages in the protected area
    <div
      className={clsx(
        addRelativeClass && "relative",
        type === "normal"
          ? "mx-auto max-w-7xl py-1 sm:px-6 sm:py-3 lg:px-8"
          : ""
      )}
    >
      <div className={clsx(type === "normal" ? "px-2" : "")}>
        <div
          style={
            type === "fillingScroll" || type === "fixedWitdhScroll"
              ? { minHeight: mainHeight }
              : type === "filling"
              ? { height: mainHeight }
              : {}
          }
          className={clsx(
            type !== "fillingScroll" &&
              type !== "fixedWitdhScroll" &&
              type !== "filling" &&
              "mx-auto max-w-2xl pb-24 pt-6 sm:px-6 sm:pb-32 sm:pt-12 lg:max-w-5xl lg:px-8 xl:max-w-6xl",
            type === "fixedWitdhScroll" && "mx-auto max-w-[110rem]"
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
}

export default MainLayout;
