import Image from "next/image";
import clsx from "clsx";

import logo_plain from "../assets/logo_plain.svg";

function Logo({
  size = "small",
}: {
  size?: "xxsmall" | "xsmall" | "small" | "medium" | "large";
}) {
  return (
    <Image
      className={clsx(
        size === "xxsmall" && "h-6 w-6",
        size === "xsmall" && "h-8 w-8",
        size === "small" && "h-10 w-10",
        size === "medium" && "h-12 w-12",
        size === "large" && "h-16 w-16"
      )}
      src={logo_plain}
      alt="Jurahilfe Logo"
      title="Jurahilfe.de | Deine interaktive Lernplattform"
    />
  );
}

export default Logo;
