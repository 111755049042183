import { firestore } from "@utils/firebase/firebase-init";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";

import { StripePrice, StripeProduct } from "@jurahilfe/shared/types";

export const fetchStripeProduct = async (
  productId: string
): Promise<StripeProduct | null> => {
  const productRef = doc(firestore, "products", productId);
  const productSnap = await getDoc(productRef);

  if (!productSnap.exists()) {
    console.warn(`No product found with id ${productId}`);
    return null;
  }

  const productData = productSnap.data() as StripeProduct;
  const pricesRef = collection(firestore, "products", productId, "prices");
  const pricesSnap = await getDocs(pricesRef);
  const prices = pricesSnap.docs.map((priceDoc) => ({
    ...priceDoc.data(),
    id: priceDoc.id,
  })) as StripePrice[];

  return {
    ...productData,
    prices,
    id: productSnap.id,
  };
};
