export const initHeap = (heapId: string, onLoad?: () => void) => {
  const script = document.createElement("script");
  script.id = "heap-analytics";
  script.type = "text/javascript";
  script.innerHTML = `window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};heap.load(${heapId});`;
  script.async = true;
  script.defer = true;
  script.onload = onLoad || null;
  document.head.appendChild(script);
};

export const removeHeap = () => {
  // Remove Heap script
  const script = document.getElementById("heap-analytics");
  if (script) script.remove();

  // Clear heap object
  (window as any).heap = undefined;

  deleteHeapCookies();
};

const deleteHeapCookies = () => {
  console.log("Deleting Heap cookies");
  // Get all cookies that start with _hp2_
  const heapCookies = document.cookie
    .split("; ")
    .filter((row) => row.startsWith("_hp2_"));

  // Delete the cookies by setting the max-age to 1 second
  if (heapCookies.length > 0) {
    console.log("Heap cookies found", heapCookies);
    heapCookies.forEach((cookie) => {
      document.cookie = `${cookie}; Secure;max-age=1`;
    });
  } else {
    console.log("No Heap _hp2_ cookies found");
  }

  console.log("document.cookie", document.cookie);
  const heapCookie = document.cookie
    .split("; ")
    .find((row) => row.startsWith("heap"));

  // Delete the cookie by setting the max-age to 1 second
  if (heapCookie) {
    document.cookie = `${heapCookie}; Secure;max-age=1`;
  } else {
    console.log("No Heap heap cookie found");
  }
};
